import React, { useMemo } from "react";
import useAppModal from "../../shared/hooks/useAppModal";

import "./AppModal.scss";

const AppModal: React.FC = ({}) => {
  const { isModalOpen, modal } = useAppModal();

  const ContentComponent = useMemo(() => modal?.content, [modal?.content]);

  const modalContent = useMemo(
    () =>
      isModalOpen ? (
        <div className={"app-modal-overlay"}>
          <div className={`app-modal-content no-visible-scroll ${modal?.className}`}>
            {ContentComponent && <ContentComponent />}
          </div>
        </div>
      ) : null,
    [ContentComponent, isModalOpen, modal?.className]
  );

  return <>{modalContent}</>;
};

export default AppModal;
