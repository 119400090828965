import React, { useMemo } from "react";
import BlockTitle from "../../common/BlockTitle";
import Icon, { IconColor, IconType } from "../../common/Icon";
import Text, { TextColor, TextFont, TextType } from "../../common/Text";
import Slider from "react-slick"

import useLangTranslation, {
  TranslationText,
} from "../../shared/hooks/useLangTranslation";

import "./HowItWorks.scss";

interface HowItWorksStepProps {
  title: string;
  subtitle: string;
  subtitleWidth: string | number;
  index: number;
}
const HowItWorksStep: React.FC<HowItWorksStepProps> = ({
  title,
  subtitle,
  subtitleWidth,
  index,
}) => {
  return (
    <div className='hiw-step-container'>
			<Text
				font={TextFont.Montserrat}
				color={TextColor.Triple3}
				type={TextType.Middle20}
				isBold>
        {title}
      </Text>

      <Text
        font={TextFont.Montserrat}
        color={TextColor.Black}
        width={subtitleWidth}
        lineHeight='40px'>
        {subtitle}
      </Text>

      <Text
        isBold
        font={TextFont.Montserrat}
        color={TextColor.Primary}
        className='hiw-step-index'>
        {index}
      </Text>
    </div>
  );
};

const HowItWorks: React.FC = () => {
  const [t] = useLangTranslation();

  const cards = useMemo(() => {
    const __cards = t(TranslationText.Section6Cards, { returnObjects: true });

    if (typeof __cards === "string") {
      return [];
    }

    return __cards as unknown as Omit<HowItWorksStepProps, "index">[];
  }, [t]);

	const settings = {
		speed: 300,
		slidesToShow: 1,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 2000,
		adaptiveHeight: true,
		centerMode: true,
		rows: 2,
		dots: true,
		responsive: [
      {
        breakpoint: 900,
				settings: {
					rows: 1,
					centerMode: false
        }
      }
    ]
  };

  return (
    <div className='hiw-container'>
      <BlockTitle text={t(TranslationText.Section6Title)} />

      <div className='hiw-step-wrapper'>
        {(cards || []).map((c, idx) => {
          return (
            <HowItWorksStep
              {...c}
              subtitleWidth={c.subtitleWidth + "px"}
              index={idx + 1}
              key={`hiws-${idx}-k`}
            />
          );
        })}
			</div>
			
			<Slider {...settings} className="hiw-step-carousel">
				{(cards || []).map((c, idx) => {
					return (
						<HowItWorksStep
							{...c}
							subtitleWidth={c.subtitleWidth + "px"}
							index={idx + 1}
							key={`hiws-${idx}-k`}
						/>
					);
				})}
			</Slider>

      <div className='hiw-icon-wrapper'>
        <Icon
          className='hiw-icon hiw-icon-1'
          size='27px'
          color={IconColor.Accent}
          type={IconType.ArrowLongLBRT}
        />
        <Icon
          className='hiw-icon hiw-icon-2'
          size='35px'
          color={IconColor.Accent}
          type={IconType.InfoShow}
        />
        <Icon
          className='hiw-icon hiw-icon-3'
          size='28px'
          color={IconColor.Accent}
          type={IconType.WebLink}
        />
        <Icon
          className='hiw-icon hiw-icon-4'
          size='25px'
          color={IconColor.Accent}
          type={IconType.User}
        />
        <Icon
          className='hiw-icon hiw-icon-5'
          size='28px'
          color={IconColor.Accent}
          type={IconType.Edit}
        />
        <Icon
          className='hiw-icon hiw-icon-6'
          size='29px'
          color={IconColor.Accent}
          type={IconType.Plus}
        />
        <Icon
          className='hiw-icon hiw-icon-7'
          size='32px'
          color={IconColor.Accent}
          type={IconType.Clock}
        />
        <Icon
          className='hiw-icon hiw-icon-8'
          size='27px'
          color={IconColor.Accent}
          type={IconType.ArrowLongLBRT}
        />
      </div>
    </div>
  );
};

export default HowItWorks;
