import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import Dropdown from "react-dropdown";

import { AppRoutingPath } from "../../App";
import Button, { ButtonType } from "../../common/Button";
import Icon, { IconColor, IconType } from "../../common/Icon";
import Text, { TextColor, TextFont } from "../../common/Text";

import useLangTranslation, {
  TranslationLanguages,
  TranslationText,
} from "../../shared/hooks/useLangTranslation";

import "./Header.scss";
import useAppModal from "../../shared/hooks/useAppModal";
import ModalLeaveRequest from "../ModalLeaveRequest";

const HeaderNavigation = ({ close } : { close: Function }) => {
  const [t] = useLangTranslation();
  const { isModalOpen } = useAppModal();
  const navigate = useNavigate();

  const onChangeNavigation = useCallback(
    (route: AppRoutingPath) => {
      if (isModalOpen) {
        return;
			}
			close()
      navigate(route, { replace: window.location.pathname === AppRoutingPath.Home });
    },
    [isModalOpen, navigate]
  );

  return (
    <div className={`header-navigation`}>
      <Text
        noWrap
        font={TextFont.Montserrat}
        color={TextColor.Triple3}
        onClick={() => {
          onChangeNavigation(AppRoutingPath.HomeWhy);
        }}>
        {t(TranslationText.HeaderWhy)}
      </Text>

      <Dropdown
        className='header-dropdown'
        baseClassName='header-dropdown-base'
        menuClassName='header-dropdown-menu'
        arrowClassName='header-dropdown-arrow'
        controlClassName='header-dropdown-control'
        placeholderClassName='header-dropdown-placeholder'
        disabled={isModalOpen}
        placeholder={t(TranslationText.HeaderProduct)}
        value={t(TranslationText.HeaderProduct)}
        arrowOpen={<Icon type={IconType.ArrowheadDown} width='16px' onClick={() => {}} />}
        arrowClosed={
          <Icon type={IconType.ArrowheadDown} width='16px' onClick={() => {}} />
        }
        options={[
          { label: t(TranslationText.HeaderProduct), value: AppRoutingPath.HomeProduct },
          {
            label: t(TranslationText.HeaderFeatures),
            value: AppRoutingPath.HomeProductFeatures,
          },
          {
            label: t(TranslationText.HeaderHowItWorks),
            value: AppRoutingPath.HomeProductHow,
          },
          {
            label: t(TranslationText.HeaderProductTour),
            value: AppRoutingPath.HomeProductTour,
          },
        ]}
        onChange={(opt) => {
          onChangeNavigation(opt.value as AppRoutingPath);
        }}
      />

      <Text
        noWrap
        font={TextFont.Montserrat}
        color={TextColor.Triple3}
        onClick={() => {
          onChangeNavigation(AppRoutingPath.HomePricing);
        }}>
        {t(TranslationText.HeaderPricing)}
      </Text>
      <Text
        noWrap
        font={TextFont.Montserrat}
        color={TextColor.Triple3}
        onClick={() => {
          onChangeNavigation(AppRoutingPath.HomeAbout);
        }}>
        {t(TranslationText.HeaderAboutUs)}
      </Text>
    </div>
  );
};

const HeaderDemo: React.VFC = () => {
  const [t] = useLangTranslation();
	const { onOpenModal, onCloseModal } = useAppModal();

  const handleOnOpenModal = useCallback(() => {
    onOpenModal({
      content: () => <ModalLeaveRequest onCloseModal={onCloseModal} />,
      className: "modal-wrapper",
    });
	}, [onCloseModal, onOpenModal]);
	
	return <div className='header-demo'>
		<Text font={TextFont.Montserrat} color={TextColor.Triple3} noWrap>
			{t(TranslationText.CommonTry4Free)}
		</Text>

		<Button
			isTool
			type={ButtonType.DarkGrey}
			className='on-hover'
			onClick={handleOnOpenModal}>
			<Text noWrap font={TextFont.Montserrat} color={TextColor.White}>
				{t(TranslationText.CommonGetDemo)}
			</Text>
			</Button>
	</div>
}

const HeaderLanguage: React.VFC = () => {
  const [t, changeLanguage, lang] = useLangTranslation();

  return (
    <div className='header-language'>
      <Dropdown
        className='header-dropdown'
        baseClassName='header-dropdown-base'
        menuClassName='header-dropdown-menu'
        arrowClassName='header-dropdown-arrow'
        controlClassName='header-dropdown-control'
        placeholderClassName='header-dropdown-placeholder'
        placeholder={"EN"}
        value={lang}
        arrowOpen={<Icon type={IconType.ArrowheadDown} width='16px' onClick={() => {}} />}
        arrowClosed={
          <Icon type={IconType.ArrowheadDown} width='16px' onClick={() => {}} />
        }
        options={[
          { label: "EN", value: TranslationLanguages.English },
          { label: "UA", value: TranslationLanguages.Ukrainian },
        ]}
        onChange={(opt) => {
          changeLanguage(opt.value as TranslationLanguages);
        }}
      />
    </div>
  );
};

interface HeaderProps { }

const HeaderMenu: React.VFC = () => {
	const [open, setOpen] = useState(false)
	return <div className="header-menu">
		<Icon
			type={open ? IconType.Close : IconType.Menu}
			color={IconColor.Dark}
			width='24px'
			height='24px'
			onClick={() => setOpen(!open)}
		/>
		<div className={`header-menu-content ${open ? 'open' : ''}`}>
			<HeaderNavigation close={() => setOpen(false)} />
			<HeaderLanguage />
			<HeaderDemo />
		</div>
	</div>
}

const Header: React.VFC<HeaderProps> = ({}) => {
	const navigate = useNavigate();
	const handleLogoClick = (e:any) => {
		e.preventDefault();
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
		if (window.scrollY) {
			setTimeout(() => {
				navigate(AppRoutingPath.Home);
			}, 800);
		} else {
			navigate(AppRoutingPath.Home);
		}
	}

  return (
    <div className='header-container'>
      <Icon
        type={IconType.UStork}
        width='132px'
        height='30px'
        onClick={handleLogoClick}
      />
      <HeaderNavigation close={() => {}} />
      <HeaderDemo />
			<HeaderLanguage />
			<HeaderMenu />
    </div>
  );
};

export default Header;
