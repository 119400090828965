import React from "react";
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Employee from "../../assets/block-2/Employee.svg";
import Manager from "../../assets/block-2/Manager.svg";
import Decision from "../../assets/block-2/Decision.svg";

import EmployeeUA from "../../assets/block-2/EmployeeUA.svg";
import ManagerUA from "../../assets/block-2/ManagerUA.svg";
import DecisionUA from "../../assets/block-2/DecisionUA.svg";

import useLangTranslation, {
  TranslationText,
} from "../../shared/hooks/useLangTranslation";

import "./SubtitleCarousel.scss";

const SubtitleCarousel= ({}) => {
  const [t, , lang] = useLangTranslation();
	const isDefaultIcon = lang === 'en'

	const settings = {
		speed: 300,
		slidesToShow: 3,
    responsive: [
      {
        breakpoint: 800,
				settings: {
					infinite: true,
					// autoplay: true,
					autoplaySpeed: 2000,
					slidesToShow: 1,
        }
      }
    ]
  };
  return (
		<Slider {...settings} className="subtitle-carousel">
			<div className="slide slide-1 img-non-selectable">
				<img src={isDefaultIcon ? Employee : EmployeeUA} alt='Employee' />
			</div>
			<div className="slide slide-2 img-non-selectable">
				<img src={isDefaultIcon ? Manager : ManagerUA} alt='Manager' />
			</div>
			<div className="slide slide-3 img-non-selectable">
				<img src={isDefaultIcon ? Decision : DecisionUA} alt='Decision' />
			</div>
		</Slider>
  );
};

export default SubtitleCarousel;
