import { useState } from "react"
import Text, { TextColor, TextType } from "../../common/Text";
import Button, { ButtonType } from "../../common/Button";
import "./CookiesPopup.scss"
import useLangTranslation, {
  TranslationNamespace,
} from "../../shared/hooks/useLangTranslation";

const getCookie = () => document.cookie.replace(/(?:(?:^|.*;\s*)udl_cookies\s*=\s*([^;]*).*$)|^.*$/, "$1");
const setCookiesAccepted = (value : any) => {
	document.cookie = `udl_cookies=${!!value}`
}  

const CookiesPopup = () => {
	const [t] = useLangTranslation(TranslationNamespace.Modals);
	const [show, setShow] = useState<boolean>(true)
	const onReject = () => {
		setShow(false)
		setCookiesAccepted(false)
	}
	const onAccept = () => {
		setShow(false)
		setCookiesAccepted(true)
	}

	if(getCookie()) return null

	return <div className="cookies-popup" style={{ animationName: show ? 'fade-up' : 'fade-down' }}>
		<div className="cookies-popup-content">
			<Text isBold type={TextType.Middle20} color={TextColor.GreyDark}>{t("Cookies.Title")}</Text>
			<Text type={TextType.Small} color={TextColor.GreyDark}>{t("Cookies.Subtitle")} </Text>
		</div>
		<div className="cookies-popup-bottom">
			<Button type={ButtonType.Grey} onClick={onReject} width={"100%"}>
				<Text color={TextColor.Grey}>Reject</Text>
			</Button>
			<Button type={ButtonType.Primary} onClick={onAccept} width={"100%"}>
				<Text isBold color={TextColor.Triple3}>Accept</Text>
			</Button>
		</div>
	</div>
}

export default CookiesPopup