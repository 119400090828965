import React from "react";

import Icon, { IconColor, IconType } from "../../common/Icon";
import Text, { TextColor, TextFont, TextType } from "../../common/Text";
import Spacer from "../../common/Spacer";
import BlockTitle from "../../common/BlockTitle";
import HumanWithPencilImg from "../../assets/Human_with_pencil.svg";
import StagesOfIdeation from "./StagesOfIdeation"
import useLangTranslation, {
  TranslationText,
} from "../../shared/hooks/useLangTranslation";

import "./WhyUStork.scss";

const WhyUStork: React.FC = () => {
  const [t] = useLangTranslation();

  return (
    <div className='why-container'>
      <div className='d-flex f-col why-text'>
        <BlockTitle text={t(TranslationText.Section3Title)} />

        <Text
          color={TextColor.Triple3}
          font={TextFont.Montserrat}
          type={TextType.Middle20}>
          {t(TranslationText.Section3Subtitle1)}
        </Text>

        <Text
          color={TextColor.Triple3}
          font={TextFont.Montserrat}
          type={TextType.Middle20}>
          {t(TranslationText.Section3Subtitle2)}
        </Text>
      </div>

			<StagesOfIdeation />

      <div className='why-bg'>
        <img
          src={HumanWithPencilImg}
          alt='HumanWithPencil'
          className='img-non-selectable why-bg-human'
        />

        <Icon
          type={IconType.User}
          color={IconColor.Accent}
          size='25px'
          className='why-bg-icons why-bg-icons-1'
        />
        <Icon
          type={IconType.Search}
          color={IconColor.Accent}
          size='28px'
          className='why-bg-icons why-bg-icons-2'
        />
        <Icon
          type={IconType.InfoShow}
          color={IconColor.Accent}
          size='35px'
          className='why-bg-icons why-bg-icons-3'
        />
        <Icon
          type={IconType.WebLink}
          color={IconColor.Accent}
          size='28px'
          className='why-bg-icons why-bg-icons-4'
        />
        <Icon
          type={IconType.ArrowLongLBRT}
          color={IconColor.Accent}
          size='26px'
          className='why-bg-icons why-bg-icons-5'
        />

        <Icon
          type={IconType.Plus}
          color={IconColor.Accent}
          size='30px'
          className='why-bg-icons why-bg-icons-6'
        />

        <Icon
          type={IconType.ArrowRightReverse}
          color={IconColor.Accent}
          size='28px'
          className='why-bg-icons why-bg-icons-7'
        />
      </div>
    </div>
  );
};

export default WhyUStork;
