export const enum PricingCardPeriodType {
  Monthly = "month",
  Yearly = "year",
}

export const enum PricingCardPlanName {
  Free = "Free",
  Business = "Business",
  Enterprise = "Enterprise",
}
