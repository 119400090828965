import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import ReactPageScroller from "react-page-scroller";

import Title from "./components/Title";
import Subtitle from "./components/Subtitle";
import SubtitleCarousel from "./components/SubtitleCarousel";
import WhyUStork from "./components/WhyUStork";
import Product from "./components/Product";
import Features from "./components/Features/Features";
import HowItWorks from "./components/HowItWorks";
import ProductTour from "./components/ProductTour";
import Pricing from "./components/Pricing";
import AboutUs from "./components/AboutUs";
import Footer from "./components/Footer";
// import PageScroller from "./common/PageScroller";
// import Test from './test'

import MotionDiv, { MotionDivVariant } from "./common/MotionDiv";

import useAdaptiveLayout from "./shared/hooks/useAdaptiveLayout";

const LandingTabQuery = "tab";
const enum LandingTab {
  Why = "why",
  Product = "product",
  ProductFeatures = "features",
  ProductHow = "how-it-works",
  ProductTour = "tour",
  Pricing = "pricing",
  About = "about",
  Contact = "contact",
}

const landingTabs2RefIndex: Record<LandingTab, number> = {
  [LandingTab.Why]: 2,
  [LandingTab.Product]: 3,
  [LandingTab.ProductFeatures]: 4,
  [LandingTab.ProductHow]: 5,
  [LandingTab.ProductTour]: 6,
  [LandingTab.Pricing]: 7,
  [LandingTab.About]: 8,
  [LandingTab.Contact]: 8,
};

const landingTabs2RefIndexTablet: Record<LandingTab, number> = {
  [LandingTab.Why]: 2,
  [LandingTab.Product]: 3,
  [LandingTab.ProductFeatures]: 4,
  [LandingTab.ProductHow]: 5,
  [LandingTab.ProductTour]: 6,
  [LandingTab.Pricing]: 7,
  [LandingTab.About]: 8,
  [LandingTab.Contact]: 9,
};

const landingTabs2RefIndexMobile: Record<LandingTab, number> = {
  [LandingTab.Why]: 3,
  [LandingTab.Product]: 4,
  [LandingTab.ProductFeatures]: 5,
  [LandingTab.ProductHow]: 6,
  [LandingTab.ProductTour]: 7,
  [LandingTab.Pricing]: 8,
  [LandingTab.About]: 9,
  [LandingTab.Contact]: 10,
};

const Landing: React.FC = () => {
  const [query] = useSearchParams();
  const queryTab = useMemo(
    () => query.get(LandingTabQuery) as LandingTab | null,
    [query]
  );
  const [currentTab, setCurrentTab] = useState(0);
	useEffect(() => {
		const tabs = adaptiveProps.isMobile ? landingTabs2RefIndexMobile : adaptiveProps.isFooterMobile ? landingTabs2RefIndexTablet : landingTabs2RefIndex;
		if (queryTab) {
      const tabIndex = tabs[queryTab];
      if (typeof tabIndex === "number") {
        setCurrentTab(tabIndex);
      }
    } else {
      setCurrentTab(0);
    }
  }, [queryTab]);
	
	// return <Test />

	// return <PageScroller>
	// </PageScroller>

	const adaptiveProps = useAdaptiveLayout({
		800: {
			isMobile: true,
			isFooterMobile: true
		},
		1000: {
			isMobile: false,
			isFooterMobile: true
		},
		0: {
			isMobile: false,
			isFooterMobile: false
		}
	})

  return (
    <MotionDiv
      initial={MotionDivVariant.DownInitial}
      animate={MotionDivVariant.DownIn}
      exit={MotionDivVariant.UpOut}>
			<ReactPageScroller
				customPageNumber={currentTab}
				renderAllPagesOnFirstRender
			>
        <Title />
				{adaptiveProps.isMobile && <Subtitle />}
				{adaptiveProps.isMobile && <SubtitleCarousel />}
				{!adaptiveProps.isMobile && <div style={{ position: 'relative' }}>
					<Subtitle />
					<SubtitleCarousel />
				</div>}
        <WhyUStork />
        <Product />
        <Features isMobile={adaptiveProps.isMobile} />
        <HowItWorks />
        <ProductTour />
				<Pricing />
				{adaptiveProps.isFooterMobile && <AboutUs />}
				{adaptiveProps.isFooterMobile && <Footer />}
				{!adaptiveProps.isFooterMobile && <div style={{ position: 'relative' }}>
					<AboutUs />
					<Footer />
				</div>}
      </ReactPageScroller>
    </MotionDiv>
  );
};

export default Landing;
