import React from "react";

import Spacer from "../../common/Spacer";
import Text, { TextFont, TextType } from "../../common/Text";
import Icon, { IconColor, IconType } from "../../common/Icon";

import useLangTranslation, {
  TranslationText,
} from "../../shared/hooks/useLangTranslation";

import "./ProductTour.scss";

const ProductTour: React.FC = () => {
  const [t] = useLangTranslation();

  return (
    <div className='pt-container'>
      <Text
        type={TextType.Large40}
        font={TextFont.Montserrat}
        lineHeight='40px'
        style={{
          color: "#2B1C08",
          zIndex: 2,
        }}>
        {t(TranslationText.Section7Title)}
      </Text>

      <Spacer top='60px' />

      <div className='pt-video-wrapper'>
				<div className='pt-video'>
					<iframe
						src='https://www.youtube.com/embed/iWdfijJuSfQ'
						title='U.Stork Product Tour | YouTube Video Player'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen
					/>
				</div>
      </div>

      {/* <Spacer top='70px' /> */}

      <Icon
        type={IconType.ArrowRightReverse}
        color={IconColor.White}
        size='29px'
        className='pt-icon pt-icon-1'
      />
      <Icon
        type={IconType.Plus}
        color={IconColor.White}
        size='29px'
        className='pt-icon pt-icon-2'
      />
      <Icon
        type={IconType.WaveMedium}
        color={IconColor.None}
        className='pt-icon pt-icon-3'
        width='90px'
      />
      <Icon
        type={IconType.WaveMedium}
        color={IconColor.None}
        className='pt-icon pt-icon-4'
        width='77px'
      />
      <Icon
        type={IconType.InfoShow}
        color={IconColor.White}
        className='pt-icon pt-icon-5'
        width='35px'
      />
      <Icon
        type={IconType.WebLink}
        color={IconColor.White}
        className='pt-icon pt-icon-6'
        width='35px'
      />
      <Icon
        type={IconType.WaveShort}
        color={IconColor.None}
        className='pt-icon pt-icon-7'
        width='51px'
      />
    </div>
  );
};

export default ProductTour;
