import React from "react";
import Icon, { IconColor, IconType } from "../../common/Icon";
import Spacer from "../../common/Spacer";
import Text, { TextColor, TextFont, TextType } from "../../common/Text";
import useAdaptiveLayout, {
  AdaptiveLayoutResolution,
} from "../../shared/hooks/useAdaptiveLayout";
import useLangTranslation, {
  TranslationText,
} from "../../shared/hooks/useLangTranslation";

export interface FeaturesContentProps {
  title: (string | 0 | 1)[];
  subtitle: string;
  content: JSX.Element;

  wrapperClassName?: string;
  titleClassName?: string;
  subtitleWidth?: string | number;
}

const FeaturesContent: React.FC<FeaturesContentProps> = ({
  title,
  subtitle,
  content,
  wrapperClassName = "",
  titleClassName = "",
  subtitleWidth = "365px",
}) => {
  const [t] = useLangTranslation();
  const adaptiveLayout = useAdaptiveLayout({
    700: {
      icon: {
        width: "46px",
        height: "20px",
        marginTop: "6px",
      },
      text: {
        type: TextType.Large38,
        lineHeight: "24px",
      },
      titleSpacer: "30px",
      title: {
        type: TextType.Middle20,
      },
    },
    1050: {
      icon: {
        width: "78px",
        height: "34px",
        marginTop: "10px",
      },
      text: {
        type: TextType.H2,
        lineHeight: "40px",
      },
      titleSpacer: "50px",
      title: {
        type: TextType.Large40,
      },
		},
		1600: {
      icon: {
        width: "116px",
        height: "50px",
        marginTop: "23px",
      },
      text: {
        type: TextType.H1,
        lineHeight: "81px",
      },
      titleSpacer: "32px",
      title: {
        type: TextType.Middle20,
      },
    },
    [AdaptiveLayoutResolution.Desktop]: {
      icon: {
        width: "116px",
        height: "50px",
        marginTop: "23px",
      },
      text: {
        type: TextType.H1,
        lineHeight: "81px",
      },
      titleSpacer: "80px",
      title: {
        type: TextType.Large40,
      },
    },
  });

  return (
    <div className={`features-container ${wrapperClassName}`}>
      <Text
        font={TextFont.Montserrat}
        type={adaptiveLayout.title.type}
        color={TextColor.Triple3}>
        {t(TranslationText.Section5Title)}
      </Text>

      <div className={"d-flex f-col features-content"}>
        <div className={`features-content-title ${titleClassName}`}>
          {title.map((t, idx) => {
            const __key = `ft-${idx}-${t}`;

            if (t === 1) {
              return <Spacer key={__key} right='100%' bottom='1px' />;
            } else if (t === 0) {
              return (
                <Icon
                  key={__key}
                  type={IconType.BigLongO}
                  color={IconColor.Dark}
                  width={adaptiveLayout.icon.width}
                  height={adaptiveLayout.icon.height}
                  style={{ marginTop: adaptiveLayout.icon.marginTop }}
                />
              );
            } else {
              return (
                <Text
                  key={__key}
                  noWrap
                  isBold
                  font={TextFont.Montserrat}
                  color={TextColor.GreyDark}
                  {...adaptiveLayout.text}>
                  {t}
                </Text>
              );
            }
          })}
        </div>

        <Spacer top={adaptiveLayout.titleSpacer} />

        <Text
          isBold
          fontWeight={500}
          lineHeight={"40px"}
          className='features-content-subtitle'
          font={TextFont.Montserrat}
          color={TextColor.GreyDark}
          style={{ width: subtitleWidth }}>
          {subtitle}
        </Text>

				<div className="features-images">
					{content}
				</div>
      </div>
    </div>
  );
};

export default FeaturesContent;
