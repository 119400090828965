import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AppModal,
  appModalClose,
  AppModalConfig,
  appModalOpen,
  selectAppModal,
} from "../../store";

const useAppModal = (): {
  isModalOpen: boolean;
  modal: AppModal;
  onOpenModal: (modalData: AppModalConfig) => void;
  onCloseModal: () => void;
} => {
  const dispatch = useDispatch();
  const modal = useSelector(selectAppModal);

  // saved state is required so the modal content appears correct when it's closing
  const [saved, setSaved] = useState<AppModal>(modal);
  useEffect(() => {
    if (modal) {
      setSaved(modal);
    } else if (!modal) {
      setTimeout(() => setSaved(null), 500);
    }
  }, [modal]);

  const onOpenModal = useCallback(
    (modalConfig: AppModalConfig) => {
      console.log(modalConfig);

      dispatch(appModalOpen(modalConfig));
    },
    [dispatch]
  );
  const onCloseModal = useCallback(() => dispatch(appModalClose()), [dispatch]);

  return { isModalOpen: !!modal, modal: saved, onOpenModal, onCloseModal };
};

export default useAppModal;
