import { useCallback} from 'react'
import FeaturesContent from "./FeaturesContent";

import IE from "../../assets/IE2.png";
import PF from "../../assets/PF1.png";
import EPS from "../../assets/EPS1.png";
import GDIC from "../../assets/GDIC1.png";
import UCL from "../../assets/UCL2.png";

import IEMobile from "../../assets/features/IEMobile.png"
import PFMobile from "../../assets/features/PFMobile.png"
import EPSMobile from "../../assets/features/EPSMobile.png";
import GDICMobile from "../../assets/features/GDICMobile.png";
import UCLMobile from "../../assets/features/UCLMobile.png";

import useLangTranslation, { TranslationText, TranslationLanguages } from "../../shared/hooks/useLangTranslation";
import "./Features.scss";
import Slider from 'react-slick'
import useAdaptiveLayout, { AdaptiveLayoutResolution } from "../../shared/hooks/useAdaptiveLayout";
interface FeaturesProps {
	isMobile: Boolean
}

const Features = ({ isMobile } : FeaturesProps) => {
	const [t, , lang] = useLangTranslation();
	const __getTitle = useCallback(
    (__t: TranslationText) => {
      const __translation = t(__t, { returnObjects: true });

      if (__translation === __t) {
        return [];
      }

      return __translation as unknown as (string | 0 | 1)[];
    },
    [t]
	);
	const settings = {
		speed: 300,
		slidesToShow: 1,
		dots: true,
		arrows: true
  };
	const isDesktop = useAdaptiveLayout({ 900: false, [AdaptiveLayoutResolution.Desktop]: true })

	return <Slider {...settings} className="features-carousel">
		<FeaturesContent
			wrapperClassName="slide-1"
			title={__getTitle(TranslationText.Section5IETitle)}
			subtitle={t(TranslationText.Section5IESubtitle)}
			subtitleWidth={isMobile ? '100%' : lang === TranslationLanguages.Ukrainian ? "395px" : "365px"}
			content={!isMobile ?
				<img src={IE} alt='ie' className='features-img features-ie-img' /> :
				<img src={IEMobile} alt='ie' className='features-img features-ie-img' /> 
			}
		/>
		<FeaturesContent
			wrapperClassName="slide-2"
			title={__getTitle(TranslationText.Section5PFTitle)}
			subtitle={t(TranslationText.Section5PFSubtitle)}
			subtitleWidth={isMobile ? '100%' : "380px"}
			content={!isMobile ?
				<img src={PF} alt='pf' className='features-img features-pf-img' /> :
				<img src={PFMobile} alt='pf' className='features-img features-pf-img' />
			}
		/>
		<FeaturesContent
			wrapperClassName="slide-3"
			title={__getTitle(TranslationText.Section5EPTitle)}
			subtitle={t(TranslationText.Section5EPSubtitle)}
			subtitleWidth={isMobile ? '100%' : undefined}
			content={!isMobile ?
				<img src={EPS} alt='EPS' className='features-img features-eps-img' /> :
				<img src={EPSMobile} alt='EPS' className='features-img features-eps-img' />
			}
		/>
		<FeaturesContent
			wrapperClassName="slide-4"
			title={__getTitle(TranslationText.Section5GDICTitle)}
			subtitle={t(TranslationText.Section5GDICSubtitle)}
			subtitleWidth={isMobile ? '100%' : "440px"}
			content={!isMobile ?
				<img src={GDIC} alt='GDIC' className='features-img features-gdic-img' /> : 
				<img src={GDICMobile} alt='GDIC' className='features-img features-gdic-img' />
			}
		/>
		<FeaturesContent
			wrapperClassName="slide-5"
			title={__getTitle(TranslationText.Section5UCLTitle)}
			subtitle={t(TranslationText.Section5UCLSubtitle)}
			subtitleWidth={isMobile ? '100%' : lang === TranslationLanguages.Ukrainian ? "580px" : "460px"}
			content={!isMobile ?
				<img src={UCL} alt='UCL' className='features-img features-ucl-img' /> :
				<img src={UCLMobile} alt='UCL' className='features-img features-ucl-img' />
			}
		/>
	</Slider>
}

export default Features