import { useCallback, useState } from "react"
import axios from "axios"

export enum Purpose {
	Contact = 'contact-us',
	Demo = 'try-for-free'
}

const useFormSubmit = (purpose : Purpose) : any => {
	const [isSubmited, setIsSubmited] = useState<boolean>(false)
	const [error, setError] = useState<string>('')

	const onSubmit = useCallback(async (locale: string, data: any) => {
		try {
			await axios.post(`${process.env.REACT_APP_API_HOST}/email-templates/${purpose}/send-to-manager`, { locale, data })
			setIsSubmited(true)
		} catch (error : any) {
			console.log(error.message)		
			setError(error.message)
		}
	}, [purpose])

	return [isSubmited, onSubmit, error]
}

export default useFormSubmit