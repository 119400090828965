import React from "react";

import Text, { TextColor, TextFont, TextType } from "../../common/Text";
import Icon, { IconColor, IconType } from "../../common/Icon";

import HumanHead from "../../assets/block-2/HumanHead.png";

import useLangTranslation, {
  TranslationText,
} from "../../shared/hooks/useLangTranslation";

import "./Subtitle.scss";

interface SubtitleProps {}

const Subtitle: React.VFC<SubtitleProps> = ({}) => {
  const [t, , lang] = useLangTranslation();
  return (
    <div className='landing-subtitle-container' style={{backgroundImage: 'url(/backs/block_2.png)'}}>
      <div className='landing-subtitle-silent-container'>
        <div className='landing-subtitle-silent-user img-non-selectable'>
          <img src={HumanHead} alt='HumanHead' />
        </div>

        <Text
          type={TextType.Middle20}
          font={TextFont.Montserrat}
          color={TextColor.Triple3}
          lineHeight='40px'>
          {t(TranslationText.Section2Title)}
        </Text>
      </div>


      {/* <Icon type={IconType.Grid} className='landing-subtitle-bg-icon-1' size='700px' />

      <Icon
        type={IconType.WebLink}
        color={IconColor.White}
        className='landing-subtitle-bg-icon landing-subtitle-bg-icon-2'
        size='28px'
      />
      <Icon
        type={IconType.User}
        color={IconColor.White}
        className='landing-subtitle-bg-icon landing-subtitle-bg-icon-3'
        size='25px'
      />
      <Icon
        type={IconType.Search}
        color={IconColor.White}
        className='landing-subtitle-bg-icon landing-subtitle-bg-icon-4'
        size='27px'
      />
      <Icon
        type={IconType.Plus}
        color={IconColor.White}
        className='landing-subtitle-bg-icon landing-subtitle-bg-icon-5'
        size='29px'
      />
      <Icon
        type={IconType.ArrowLongLBRT}
        color={IconColor.White}
        className='landing-subtitle-bg-icon landing-subtitle-bg-icon-6'
        size='25px'
      />

      <Icon
        type={IconType.WaveShort}
        color={IconColor.None}
        className='landing-subtitle-bg-icon landing-subtitle-bg-icon-7'
        size='50px'
      />
      <Icon
        type={IconType.WaveShort}
        color={IconColor.None}
        className='landing-subtitle-bg-icon landing-subtitle-bg-icon-8'
        size='50px'
      />
      <Icon
        type={IconType.WaveMedium}
        color={IconColor.None}
        className='landing-subtitle-bg-icon landing-subtitle-bg-icon-9'
        size='75px'
      />
      <Icon
        type={IconType.WaveMedium}
        color={IconColor.None}
        className='landing-subtitle-bg-icon landing-subtitle-bg-icon-10'
        size='75px'
      />
      <Icon
        type={IconType.WaveLong}
        color={IconColor.None}
        className='landing-subtitle-bg-icon landing-subtitle-bg-icon-11'
        size='145px'
      /> */}
    </div>
  );
};

export default Subtitle;
