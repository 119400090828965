/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import Text, { TextColor, TextFont } from "../../common/Text";

import useLangTranslation, {
  TranslationNamespace,
  TranslationText,
} from "../../shared/hooks/useLangTranslation";

const enum DocumentNavItemType {
  Text = "text",
  Sub1 = "sub1",
}

interface DocumentNavItemProps {
  index: string | number | null;
  type: DocumentNavItemType;
  text: string;
  weight: number;
  onClick: () => void;
}

const __typeToClassName = {
  [DocumentNavItemType.Text]: "doc-nav-text",
  [DocumentNavItemType.Sub1]: "doc-nav-text doc-nav-text-sub",
};

const DocumentNavItem: React.VFC<DocumentNavItemProps> = ({
  index,
  type,
  text,
  weight,
  onClick,
}) => {
  return (
    <Text
      index={index}
      onClick={onClick}
      fontWeight={weight}
      font={TextFont.Montserrat}
      color={TextColor.Black}
      className={__typeToClassName[type]}>
      {text}
    </Text>
  );
};

interface DocumentNavProps {
  ns: TranslationNamespace;
  onItemClick: (index: number) => void;
  navBlocks: React.MutableRefObject<HTMLParagraphElement[]>;
}

const DocumentNav: React.VFC<DocumentNavProps> = ({ onItemClick, navBlocks, ns }) => {
  const [t] = useLangTranslation(ns);

  const navigation = useMemo(() => {
    const __navigation = t(TranslationText.DocumentNavigation, { returnObjects: true });

    if (typeof __navigation === "string") {
      return [];
    }

    return __navigation as unknown as DocumentNavItemProps[];
  }, [t]);

  const isRefVisible = useCallback((blockIndex: number) => {
    const ref = navBlocks.current[blockIndex];

    if (ref) {
      const top = ref.offsetTop;
      const isVis =
        top >= window.scrollY - 120 &&
        top <= window.scrollY + 120 + window.innerHeight - 150;

      return isVis;
    }
    return false;
  }, []);
  const mapVisibleRefsToWeight = useCallback<() => number[]>(
    () =>
      navBlocks.current.length
        ? navBlocks.current.map((_, idx) => (isRefVisible(idx) ? 600 : 500))
        : Array(12).fill(500),
    [isRefVisible, navBlocks]
  );

  const [visibleMap, setVisibleMap] = useState<number[]>(mapVisibleRefsToWeight());
  const __handleScroll = useMemo(
    () =>
      _.debounce(() => {
        setVisibleMap(mapVisibleRefsToWeight());
      }, 400),
    []
  );

  useEffect(() => {
    const onScroll = () => __handleScroll();
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className='doc-nav-container'>
      {navigation.map((nav, idx) => {
        return (
          <DocumentNavItem
            {...nav}
            weight={visibleMap[idx]}
            key={`pp-nav-item-${nav.index}-${idx}`}
            onClick={() => onItemClick(idx)}
          />
        );
      })}
    </div>
  );
};

export default DocumentNav;
