import React from "react";
import useAdaptiveLayout from "../../shared/hooks/useAdaptiveLayout";
import Icon, { IconColor, IconProps, IconType } from "../Icon";
import Text, { TextColor, TextFont, TextProps, TextType } from "../Text";

import "./BlockTitle.scss";

interface BlockTitleProps {
  text: string;
  containerProps?: JSX.IntrinsicElements["div"];
  textProps?: TextProps;
  iconProps?: IconProps;
}

const BlockTitle: React.VFC<BlockTitleProps> = ({
  text,
  containerProps,
  textProps,
  iconProps,
}) => {
	
	const size = useAdaptiveLayout({
    500: TextType.Large,
		0: TextType.Large40,
	});
  return (
    <div className='d-flex f-acenter' {...containerProps}>
      <Text
        className='block-title-text'
        color={TextColor.Triple3}
        font={TextFont.Montserrat}
        type={size}
        {...textProps}>
        {text}
      </Text>
      <Icon type={IconType.Edit} size='28px' color={IconColor.Accent} {...iconProps} />
    </div>
  );
};

export default BlockTitle;
