import React, {
  CSSProperties,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { ColorRGB, HEXtoRGB, RGBAtoString } from "../../shared/functions/colorsMath";
import Icon, { IconColor, IconProps } from "../Icon";
import Spacer from "../Spacer";
import Text, { TextColor, TextProps } from "../Text";

type ButtonBtnTypeProp = "submit" | "reset" | "button";

export enum ButtonType {
  Primary = "primary",
  Secondary = "secondary",
  DarkGrey = "dGrey",
  Grey = "grey",
  White = "white",
  Warning = "warning",
}

export type ButtonOnClickHandler = MouseEventHandler<
  HTMLButtonElement & { _name?: string }
>;

interface ButtonColorState {
  default: CSSProperties["background"];
  active: CSSProperties["background"];
  hover: CSSProperties["background"];
}

interface ButtonStyledProps {
  isTool: boolean;
  isOutline: boolean;
  isActive: boolean;
  borderRadius: CSSProperties["borderRadius"];
  background: ButtonColorState;
  width?: CSSProperties["width"];
}
const ButtonStyled = styled.button<ButtonStyledProps>`
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  outline: none;
  border: none;
  height: fit-content;
  width: ${({ width }) => width ?? "fit-content"};
  transition: all 0.35s ease-in-out;

  padding: ${({ isTool }) => (isTool ? "8px 20px" : "17px 32px")};
  border-radius: ${({ borderRadius }) => borderRadius};
  border: ${({ background, isOutline }) =>
    isOutline ? `2px solid ${background.default}` : "none"};
  background: ${({ background, isOutline, isActive }) =>
    isOutline ? "none" : isActive ? background.active : background.default};

  &:hover {
    background: ${({ background }) => background.hover};
  }
  &:active {
    background: ${({ background }) => background.active};
  }
`;

export interface ButtonProps {
  btnType?: ButtonBtnTypeProp;
  type?: ButtonType;
  children?: ReactNode;
  isTool?: boolean;
  onClick?: ButtonOnClickHandler;
  isOnClickReset?: boolean;
  disabled?: boolean;
  className?: string;
  width?: CSSProperties["width"];
  style?: CSSProperties;
  label?: TextProps | null;
  leftIcon?: IconProps | null;
  leftIconSpace?: string;
  isRightRounded?: boolean;
  isLeftRounded?: boolean;
  isActive?: boolean;
  isActiveInvert?: boolean;
  isOutline?: boolean;
  name?: string;
}

const Button: React.FC<ButtonProps> = ({
  btnType = "button",
  type = ButtonType.White,
  children,
  isTool,
  onClick,
  isOnClickReset = false,
  disabled,
  className = "",
  width,
  style = {},
  label = null,
  leftIcon = null,
  leftIconSpace = "10px",
  isOutline = false,
  isRightRounded = true,
  isLeftRounded = true,
  isActive = false,
  isActiveInvert = true,
  name = "btn",
}) => {
  const [isClicked, setIsClicked] = useState(isActive);

  const _type = useMemo(() => type ?? ButtonType.White, [type]);

  const _roundRadius = useMemo(() => (isTool ? "8px" : "12px"), [isTool]);
  const _rounds = useMemo(
    () => ({
      left: {
        top: !isLeftRounded ? "0" : _roundRadius,
        bottom: !isLeftRounded ? "0" : _roundRadius,
      },
      right: {
        top: !isRightRounded ? "0" : _roundRadius,
        bottom: !isRightRounded ? "0" : _roundRadius,
      },
    }),
    [_roundRadius, isLeftRounded, isRightRounded]
  );

  const _themed = useMemo<
    Record<ButtonType, { color?: CSSProperties["color"]; background: ButtonColorState }>
  >(
    () => ({
      [ButtonType.Primary]: {
        color: disabled ? RGBAtoString({ r: 255, g: 204, b: 0 }, 0.6) : "",
        background: {
          active: RGBAtoString({ r: 255, g: 204, b: 0 }, 0.5),
          hover: RGBAtoString({ r: 255, g: 204, b: 0 }, 0.6),
          default: "#FFCC00",
        },
      },
      [ButtonType.Secondary]: {
        color: disabled ? RGBAtoString({ r: 255, g: 204, b: 0 }, 0.6) : "",
        background: {
          active: RGBAtoString(HEXtoRGB("#FADD6A") as ColorRGB, 0.85),
          hover: RGBAtoString(HEXtoRGB("#FADD6A") as ColorRGB, 0.7),
          default: disabled ? "#ccc" : "#FADD6A",
        },
      },
      [ButtonType.DarkGrey]: {
        color: disabled ? "rgba(0, 0, 0, 0.15)" : "",
        background: {
          active: "#505050",
          hover: "#444444",
          default: "#333333",
        },
      },
      [ButtonType.Grey]: {
        color: !isActive && disabled ? "rgba(0, 0, 0, 0.15)" : "",
        background: {
          active: "rgba(0, 0, 0, 0.35)",
          hover: "rgba(0, 0, 0, 0.1)",
          default: "rgba(0, 0, 0, 0.05)",
        },
      },
      [ButtonType.White]: {
        background: {
          active: "#f0f0f0",
          hover: "#f8f8f8",
          default: "#ffffff",
        },
      },
      [ButtonType.Warning]: {
        background: {
          active: RGBAtoString(HEXtoRGB("#E53900") as ColorRGB, 0.5),
          hover: RGBAtoString(HEXtoRGB("#E53900") as ColorRGB, 0.15),
          default: RGBAtoString(HEXtoRGB("#E53900") as ColorRGB, 0.05),
        },
      },
    }),
    [disabled, isActive]
  );
  const _themedProps = useMemo(() => _themed[_type], [_themed, _type]);

  useEffect(() => {
    if (isClicked && !isActive) {
      setTimeout(() => setIsClicked(false), 350);
    }
  }, [isClicked, isActive]);

  return (
    <ButtonStyled
      isActive={isActive}
      isOutline={isOutline}
      isTool={isTool ?? false}
      borderRadius={`${_rounds.left.top} ${_rounds.right.top} ${_rounds.right.bottom} ${_rounds.left.bottom}`}
      background={_themedProps.background}
      className={`${className ?? ""} ${onClick ? "on-hover" : ""}`}
      style={style ?? {}}
      width={width}
      name={name}
      type={btnType}
      disabled={disabled}
      onClick={(e) => {
        isOnClickReset && e?.preventDefault();
        if (onClick) {
          onClick({ ...e, currentTarget: { ...e.currentTarget, _name: name ?? "" } });
        }
        setIsClicked(true);
      }}>
      {leftIcon ? (
        <>
          <Icon
            size='16px'
            opacity={
              (isClicked || isActive) && isActiveInvert
                ? "100%"
                : disabled
                ? "20%"
                : "50%"
            }
            {...leftIcon}
            color={
              (isClicked || isActive) && isActiveInvert
                ? leftIcon.color === IconColor.White
                  ? IconColor.Dark
                  : IconColor.White
                : leftIcon.color
            }
          />
          {label || children ? <Spacer right={leftIconSpace} /> : null}
        </>
      ) : null}
      {label ? (
        <Text
          {...label}
          color={
            (isClicked || isActive) && isActiveInvert
              ? label.color === TextColor.White
                ? TextColor.Black
                : TextColor.White
              : label.color
          }
          style={{
            color: _themedProps?.color?.length ? _themedProps.color : "",
          }}
        />
      ) : null}
      {children}
    </ButtonStyled>
  );
};

export default Button;
