import React, { useCallback } from "react";

import "./Title.scss";

import Uimg from "../../assets/U.svg";
import Human from "../../assets/Human.svg";
import SwitchImg from "../../assets/Switch.svg";

import Text, { TextColor, TextFont, TextType } from "../../common/Text";
import Button, { ButtonType } from "../../common/Button";
import Icon, { IconColor, IconType } from "../../common/Icon";
import useLangTranslation, {
  TranslationText,
} from "../../shared/hooks/useLangTranslation";
import useAppModal from "../../shared/hooks/useAppModal";
import ModalLeaveRequest from "../ModalLeaveRequest";

interface TitleProps {}

const Title: React.FC<TitleProps> = ({}) => {
  const [t] = useLangTranslation();
  const { onOpenModal, onCloseModal } = useAppModal();

  const handleOnOpenModal = useCallback(() => {
    onOpenModal({
      content: () => <ModalLeaveRequest onCloseModal={onCloseModal} />,
      className: "modal-wrapper",
    });
  }, [onCloseModal, onOpenModal]);
  return (
    <div className='landing-title-container' style={{backgroundImage: 'url(/backs/block_1.png)'}}>
      <div className='landing-title'>
        <div className='landing-title-header'>
          <img src={Uimg} alt='U' className='landing-title-u' />

          <Text
            noWrap
            isExtraBold
            className='landing-title-text'
            font={TextFont.Montserrat}
            color={TextColor.Triple3}>
						{/* {t(TranslationText.Section1Title1)} */}
						se the p
          </Text>

          <img src={SwitchImg} alt='Switch' className='landing-title-s' />

          <Text
            noWrap
            isExtraBold
            className='landing-title-text'
            font={TextFont.Montserrat}
            color={TextColor.Triple3}>
						{/* {t(TranslationText.Section1Title2)} */}
						wer
          </Text>
        </div>

        <Text
          font={TextFont.Montserrat}
          type={TextType.Middle}
          color={TextColor.Triple3}
          className='landing-title-subtitle'>
          {t(TranslationText.Section1TitleSub)}
        </Text>

        <Button
          isTool
          type={ButtonType.DarkGrey}
          className='on-hover landing-title-btn'
          onClick={handleOnOpenModal}>
          <Text noWrap font={TextFont.Montserrat} color={TextColor.White}>
            {t(TranslationText.CommonGetDemo)}
          </Text>
        </Button>
      </div>

      <div className='landing-bg'>
        <img src={Human} alt='Human' className='landing-bg-img landing-bg-img-2' />

        {/* <Icon
          type={IconType.HVector}
          color={IconColor.None}
          size='1100px'
          className='landing-bg-img landing-bg-img-1'
        />

        <Icon
          type={IconType.Plus}
          color={IconColor.White}
          size='61px'
          className='landing-bg-img landing-bg-icon landing-bg-icon-1'
        />
        <Icon
          type={IconType.InfoShow}
          color={IconColor.White}
          size='64px'
          className='landing-bg-img landing-bg-icon landing-bg-icon-2'
        />
        <Icon
          type={IconType.ArrowRightReverse}
          color={IconColor.White}
          size='38px'
          className='landing-bg-img landing-bg-icon landing-bg-icon-3'
        />
        <Icon
          type={IconType.Grid}
          className='landing-bg-img landing-bg-icon-4'
          size={"700px"}
        /> */}
      </div>
    </div>
  );
};

export default Title;
