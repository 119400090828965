/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from "react";

import Text, { TextColor, TextFont, TextType } from "../../common/Text";
import Spacer from "../../common/Spacer";

import useLangTranslation, {
  TranslationNamespace,
  TranslationText,
} from "../../shared/hooks/useLangTranslation";
import PrivacyPolicyLink from "./DocumentLink";
import _ from "lodash";

const enum TextElementType {
  Title = "title",
  Paragraph = "paragraph",
  Text = "text",
  Bold = "bold",
  Italic = "italic",
  Heading = "head",
  Link = "link",

  ListSub1 = "list_sub1",
  ListSub2 = "list_sub2",

  SpacerDefault = "space_default",
  SpacerParagraph = "space_paragraph",
}

type TextParagraphContent =
  | TextElementTypedData[TextElementType.Text]
  | TextElementTypedData[TextElementType.Bold]
  | TextElementTypedData[TextElementType.Link];
type TextLinkHrefType = "__selflink" | "__selflink_tab_changes" | string;
type TextIndexType = number | string | null;

interface TextElementTypedData {
  [TextElementType.Title]: {
    type: TextElementType.Title;
    noLinking?: boolean;
    content: string;
  };
  [TextElementType.Paragraph]: {
    type: TextElementType.Paragraph;
    content: TextParagraphContent[];
  };
  [TextElementType.Text]: {
    type: TextElementType.Text;
    content: string;
  };
  [TextElementType.Bold]: {
    type: TextElementType.Bold;
    content: string;
  };
  [TextElementType.Italic]: {
    type: TextElementType.Italic;
    content: string;
  };
  [TextElementType.Heading]: {
    type: TextElementType.Heading;
    index: TextIndexType;
    content: string;
  };
  [TextElementType.Link]: {
    type: TextElementType.Link;
    href: TextLinkHrefType | null;
    content: string | null;
  };
  [TextElementType.ListSub1]: {
    type: TextElementType.ListSub1;
    noAlign?: boolean;
    index: TextIndexType;
    content: TextParagraphContent[];
  };
  [TextElementType.ListSub2]: {
    type: TextElementType.ListSub2;
    noAlign?: boolean;
    index: TextIndexType;
    content: TextParagraphContent[];
  };
  [TextElementType.SpacerParagraph]: {
    type: TextElementType.SpacerParagraph;
  };
  [TextElementType.SpacerDefault]: {
    type: TextElementType.SpacerDefault;
  };
}

type TextElementTitle = TextElementTypedData[TextElementType.Title];
type TextElementParagraph = TextElementTypedData[TextElementType.Paragraph];
type TextElementText = TextElementTypedData[TextElementType.Text];
type TextElementBold = TextElementTypedData[TextElementType.Bold];
type TextElementItalic = TextElementTypedData[TextElementType.Italic];
type TextElementLink = TextElementTypedData[TextElementType.Link];
type TextElementHeading = TextElementTypedData[TextElementType.Heading];
type TextElementListSub1 = TextElementTypedData[TextElementType.ListSub1];
type TextElementListSub2 = TextElementTypedData[TextElementType.ListSub2];
type TextElementSpacerDefault = TextElementTypedData[TextElementType.SpacerDefault];
type TextElementSpacerParagraph = TextElementTypedData[TextElementType.SpacerParagraph];

type TextElement =
  | TextElementTitle
  | TextElementParagraph
  | TextElementText
  | TextElementBold
  | TextElementItalic
  | TextElementLink
  | TextElementHeading
  | TextElementListSub1
  | TextElementListSub2
  | TextElementSpacerDefault
  | TextElementSpacerParagraph;

const enum TextElementSpacerSizes {
  Default = "30px",
  Paragraph = "60px",
}

interface PrivacyPolicyTextProps {
  ns: TranslationNamespace;
  addRefOnHeading: React.RefCallback<HTMLParagraphElement>;
}

const PrivacyPolicyText: React.VFC<PrivacyPolicyTextProps> = ({
  ns,
  addRefOnHeading,
}) => {
  const [t] = useLangTranslation(ns);

  const textMarkups = useMemo(() => {
    const __tm = t(TranslationText.DocumentText, { returnObjects: true });

    if (typeof __tm === "string") {
      return [];
    }

    return __tm as unknown as TextElement[];
  }, [t]);

  const __textElToJSX = useCallback((el: TextElement): React.ReactNode => {
    const elKey = `pptext-key-${el.type}-${_.uniqueId("pptext-key-")}`;

    switch (el.type) {
      case TextElementType.ListSub1:
      case TextElementType.ListSub2:
      case TextElementType.Paragraph: {
        const content: React.ReactNode[] = [];

        for (const c of el.content) {
          content.push(__textElToJSX(c));
        }

        return (
          <Text
            key={elKey}
            font={TextFont.Montserrat}
            color={TextColor.Black}
            fontWeight={500}
            lineHeight='28px'
            className={`doc-content-text${
              el.type === TextElementType.ListSub1
                ? " sub sub-1" + (el?.noAlign ? " no-align" : "")
                : el.type === TextElementType.ListSub2
                ? " sub sub-2" + (el?.noAlign ? " no-align" : "")
                : ""
            }`}
            index={(el as TextElementListSub1)?.index}>
            {content}
          </Text>
        );
      }

      case TextElementType.Text:
        return <React.Fragment key={elKey}>{el.content}</React.Fragment>;
      case TextElementType.Italic:
      case TextElementType.Bold:
        return (
          <span className={el.type} key={elKey}>
            {el.content}
          </span>
        );

      case TextElementType.Link:
        return <PrivacyPolicyLink key={elKey} link={el.href} linkText={el.content} />;
      case TextElementType.Heading:
        return (
          <Text
            font={TextFont.Montserrat}
            key={elKey}
            color={TextColor.Black}
            type={TextType.Middle20}
            fontWeight={600}
            lineHeight='28px'
            className='doc-content-text head'
            textRef={addRefOnHeading}
            index={el.index}>
            {el.content}
          </Text>
        );

      case TextElementType.Title:
        return (
          <Text
            key={elKey}
            isCenter
            width='100%'
            textRef={el?.noLinking ? undefined : addRefOnHeading}
            font={TextFont.Montserrat}
            type={TextType.Middle}
            color={TextColor.Triple3}>
            {el.content}
          </Text>
        );

      case TextElementType.SpacerDefault:
      case TextElementType.SpacerParagraph:
        return (
          <Spacer
            key={elKey}
            top={
              el.type === TextElementType.SpacerDefault
                ? TextElementSpacerSizes.Default
                : TextElementSpacerSizes.Paragraph
            }
          />
        );

      default:
        return null;
    }
  }, []);

  const text = useMemo<React.ReactNode[]>(() => {
    const outTextElements: React.ReactNode[] = [];

    for (const textEl of textMarkups) {
      outTextElements.push(__textElToJSX(textEl));
    }

    return outTextElements;
  }, [textMarkups]);

  return <div className='doc-content-container'>{text}</div>;
};

export default PrivacyPolicyText;
