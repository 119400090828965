import useLangTranslation, {
  TranslationText,
} from "../../shared/hooks/useLangTranslation";
import useAdaptiveLayout, {
  AdaptiveLayoutResolution,
} from "../../shared/hooks/useAdaptiveLayout";

import BackgroundImageBig from "../../assets/stages-of-ideation/big.svg";
import BackgroundImageMedium from "../../assets/stages-of-ideation/medium.svg";
import BackgroundImageSmall from "../../assets/stages-of-ideation/small.svg";


const ImageSrcs = {
	big: BackgroundImageBig,
	medium:BackgroundImageMedium,
	small: BackgroundImageSmall
}

// interface StagesOfIdeationProps {
// 	variant: Variants
// }

const enum Variants {
  big = "big",
  medium = "medium",
  small = "small",
}

const StagesOfIdeation = () => {
	const [t, , lang] = useLangTranslation();

	const variant = useAdaptiveLayout({
    500: Variants.small,
    744: Variants.medium,
    800: Variants.big,
		1440: Variants.medium,
		0: Variants.big,
	});
	
	const imageSrc = ImageSrcs[variant]


	return <div className={`why-ideation img-non-selectable ${variant}`}>
		<div className={`why-ideation-title ${lang}`}>
			{t(TranslationText.Section3StagesTitle)}
		</div>

		<div className='why-ideation-step step1'>
			<div className="why-ideation__title">1</div>
			<div className="why-ideation__subtitle">
				{t(TranslationText.Section3Stages1)}
			</div>
		</div>

		<div className='why-ideation-step step2'>
			<div className="why-ideation__title">2</div>
			<div className="why-ideation__subtitle">
				{t(TranslationText.Section3Stages2)}
			</div>
		</div>

		<div className='why-ideation-step step3'>
			<div className="why-ideation__title">3</div>
			<div className="why-ideation__subtitle">
				{t(TranslationText.Section3Stages3)}
			</div>
		</div>

		<div className={`why-ideation-step step4 ${lang}`}>
			<div className="why-ideation__title">4</div>
			<div className="why-ideation__subtitle">
				{t(TranslationText.Section3Stages4)}
			</div>
		</div>

		<img src={imageSrc} alt="StagesOfIdeation" />
	</div>
}

export default StagesOfIdeation