import React,  { useState } from "react";

import "./Pricing.scss";
import useLangTranslation, {
  TranslationText,
} from "../../shared/hooks/useLangTranslation";
import { PricingCardPeriodType, PricingCardPlanName } from "./types";
import { MobileCard } from './elements'
import { PricingTable} from './PricingDesktop'
import Switch from "../../common/Switch";
interface PricingMobileProps {
  isYearly: boolean;
  switchPaymentPeriod: (p: PricingCardPeriodType) => void;
  paymentPeriod: PricingCardPeriodType;
}

const PricingMobile: React.FC<PricingMobileProps> = ({ isYearly, switchPaymentPeriod, paymentPeriod }) => {
	const [t] = useLangTranslation();
	const [activePlan, setActivePlan] = useState <number | null> (null) ;
	const tabs = [
		{
			price: 0,
			name: PricingCardPlanName.Free,
			period: paymentPeriod,
			btnText: t(TranslationText.CommonTry4Free),
		},
		{
			price: isYearly ? 50 : 5,
			name: PricingCardPlanName.Business,
			period: paymentPeriod,
			btnText: t(TranslationText.CommonGetDemo),
		},
		{
			price: '',
			name: PricingCardPlanName.Enterprise,
			period: paymentPeriod,
			btnText: t(TranslationText.CommonGetDemo),
		},
		
	]
  return (
		<div className='pricing-mob-container'>
			{tabs.map((tab, i) => (
				<React.Fragment key={i}>
					<MobileCard
						{...tab}
						onClick={() => setActivePlan(i === activePlan ? null : i)}
						isActive={i === activePlan}
						isHidden={activePlan !== null && i !== activePlan}
					/>
						{i === activePlan && <div className='pricing-table-container'>
							<Switch
								value={paymentPeriod}
								onChange={switchPaymentPeriod}
								options={[PricingCardPeriodType.Monthly, PricingCardPeriodType.Yearly]}
								getLabel={(option: string) => option === PricingCardPeriodType.Monthly ?  t(TranslationText.Section8PeriodMonthly) : t(TranslationText.Section8PeriodYearly)}
							/>
							<PricingTable activeColumn={activePlan} />
						</div>}
				</React.Fragment>)
			)}
    </div>
  );
};

export default PricingMobile;
