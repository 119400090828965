import React, { useCallback, useRef } from "react";

import MotionDiv from "../../common/MotionDiv";

import DocumentNav from "./DocumentNav";
import DocumentText from "./DocumentText";

import { TranslationNamespace } from "../../shared/hooks/useLangTranslation";

import "./Document.scss";

interface DocumentProps {
  namespace: TranslationNamespace;
}

const Document: React.VFC<DocumentProps> = ({ namespace }) => {
  const navBlocks = useRef<HTMLParagraphElement[]>([]);
  const addRefBlock = useCallback<React.RefCallback<HTMLParagraphElement>>((r) => {
    r && navBlocks.current.push(r);
  }, []);

  const gotoRefBlock = useCallback((blockIndex: number) => {
    const ref = navBlocks.current[blockIndex];

    if (ref) {
      window.scrollTo({ top: blockIndex ? ref.offsetTop - 20 : 0, behavior: "smooth" });
    }
  }, []);

  return (
    <MotionDiv>
      <div className='doc-container'>
        <DocumentNav ns={namespace} onItemClick={gotoRefBlock} navBlocks={navBlocks} />
        <DocumentText ns={namespace} addRefOnHeading={addRefBlock} />
      </div>
    </MotionDiv>
  );
};

export default Document;
