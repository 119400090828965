import styled from "styled-components"

const Switch = ({ value, onChange, options, getLabel}) => {

	return <Wrapper>	
		{options.map(option => <Option
				key={option}
				active={option === value}
				onClick={() => onChange(option)}>
				{getLabel(option)}
			</Option>)}
	</Wrapper>
}

export default Switch

const Wrapper = styled.div`
	width: 100%;
	background: rgba(255, 204, 0, 0.15);
	border-radius: 72px;
	padding: 4px 5px;
	display: flex;
	gap: 9px;
`
const Option = styled.div`
	flex-grow: 1;
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
	text-align: center;
	font-family: 'Montserrat';
	color: rgba(0, 0, 0, 0.35);
	transition: all 0.3s;
	cursor: pointer;
	border-radius: 23px;
	
	${props => props.active && `
		background: #333333;
		box-shadow: 0px 7px 4px rgba(0, 0, 0, 0.04);
		color: #fff;
	`}
`
