import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { AnimatePresence } from "framer-motion";

import Header from "./components/Header";
import Landing from "./Landing";
import Document from "./components/Document";
import useLangTranslation, {
  TranslationLanguages,
  TranslationNamespace,
} from "./shared/hooks/useLangTranslation";
import AppModal from "./components/AppModal";
import { Provider } from "react-redux";
import store from "./store";
import CookiesPopup from "./components/CookiesPopup";

interface LanguageOption {
  label: string;
  value: TranslationLanguages;
}

export const enum AppRoutingPath {
  Home = "/",
  HomeWhy = "/?tab=why",
  HomeProduct = "/?tab=product",
  HomeProductFeatures = "/?tab=features",
  HomeProductHow = "/?tab=how-it-works",
  HomeProductTour = "/?tab=tour",
  HomePricing = "/?tab=pricing",
  HomeAbout = "/?tab=about",
  HomeContact = "/?tab=contact",

  PrivacyPolicy = "/privacy-policy",
  DataSecurity = "/data-security",
  TermsOfUse = "/terms-of-use",
}

const App: React.FC = () => {
  const location = useLocation();
  const [, , currentLanguage] = useLangTranslation();

  const _defaultLanguagesMap = useMemo<Record<TranslationLanguages, LanguageOption>>(
    () => ({
      [TranslationLanguages.English]: {
        label: "EN",
        value: TranslationLanguages.English,
      },
      [TranslationLanguages.Ukrainian]: {
        label: "UA",
        value: TranslationLanguages.Ukrainian,
      },
    }),
    []
  );

  const _getCurrentLanguage = useCallback(
    (language = currentLanguage) => {
      const _availableLanguages: TranslationLanguages[] = [
        TranslationLanguages.English,
        TranslationLanguages.Ukrainian,
      ];

      let lang: TranslationLanguages | undefined = undefined;
      if (language?.length) {
        for (const al of _availableLanguages) {
          if (language.toLocaleLowerCase().includes(al)) {
            lang = al as TranslationLanguages;
            break;
          }
        }
      }

      const res = !!lang?.length
        ? _defaultLanguagesMap[lang as TranslationLanguages]
        : _defaultLanguagesMap[TranslationLanguages.English];

      return res;
    },
    [_defaultLanguagesMap, currentLanguage]
  );

  const [currAppLanguage, setCurrAppLanguage] = useState<LanguageOption>(
    _getCurrentLanguage()
  );

  useEffect(() => {
    if (currAppLanguage.value !== currentLanguage) {
      setCurrAppLanguage(_getCurrentLanguage(currentLanguage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  useEffect(() => {
    setCurrAppLanguage(_getCurrentLanguage(currentLanguage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider store={store}>
      <Header />

			<AppModal />
			
			<CookiesPopup />

      <AnimatePresence exitBeforeEnter initial={false}>
        <Routes location={location} key={location.pathname}>
          <Route path={AppRoutingPath.Home} element={<Landing />} />

          <Route
            path={AppRoutingPath.PrivacyPolicy}
            element={<Document namespace={TranslationNamespace.PrivacyPolicy} />}
          />
          <Route
            path={AppRoutingPath.DataSecurity}
            element={<Document namespace={TranslationNamespace.DataSecurity} />}
          />
          <Route
            path={AppRoutingPath.TermsOfUse}
            element={<Document namespace={TranslationNamespace.TermsOfUse} />}
          />

          <Route path='*' element={<Navigate to={AppRoutingPath.Home} />} />
        </Routes>
      </AnimatePresence>
    </Provider>
  );
};

export default App;
