import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { AppRoutingPath } from "../../App";
import Button, { ButtonType } from "../../common/Button";
import Icon, { IconType } from "../../common/Icon";
import Input from "../../common/Input";
import Spacer from "../../common/Spacer";
import Text, { TextColor, TextType } from "../../common/Text";
import useLangTranslation, {
  TranslationNamespace,
} from "../../shared/hooks/useLangTranslation";
import useFormSubmit, { Purpose } from "../../shared/hooks/useFormSubmit"

interface ModalContactUsProps {
  onCloseModal: () => void;
}

interface LeaveRequestForm {
  name: string;
  message: string;
  email: string;
  ppagreement: boolean;
}

const ModalContactUs: React.FC<ModalContactUsProps> = ({ onCloseModal }) => {
  const [t, _, lang] = useLangTranslation(TranslationNamespace.Modals);

  const [inputs, setInputs] = useState<LeaveRequestForm>({
    name: "",
    message: "",
    email: "",
    ppagreement: false,
	});
	const [isThankYou, onSubmit, error] = useFormSubmit(Purpose.Contact)
  // const [isThankYou, setIsThankYou] = useState(false);

  const onChangeInput = useCallback(
    (value: LeaveRequestForm[keyof LeaveRequestForm], field: keyof LeaveRequestForm) => {
      setInputs({ ...inputs, [field]: value });
    },
    [inputs]
  );

  const isSubmitAvailable = useMemo(
		() => !!(inputs.email.length && inputs.message.length && inputs.ppagreement),
    [inputs.email, inputs.message, inputs.ppagreement]
	);
		
	const handleSubmit = () => {
		onSubmit(lang, {
			name: inputs.name,
			email: inputs.email,
			message: inputs.message,
		})
  };

	if (isThankYou) {
    return (
      <div className='modal-thank-you'>
        <Text color={TextColor.GreyDark} type={TextType.Middle20} isExtraBold>
          {t("ThankYou.Title")}
        </Text>

        <Spacer top='8px' />

        <Text color={TextColor.GreyDark}>{t("ThankYou.Subtitle")}</Text>

        <Spacer top='24px' />

        <Button
          type={ButtonType.Secondary}
          className={`modal-btn thank-you`}
          onClick={() => {
            onCloseModal();
          }}>
          <Text isExtraBold color={TextColor.Triple3}>
            {t("Btn.OK")}
          </Text>
        </Button>
      </div>
    );
  }

  return (
    <form
      className='d-flex f-col modal-600'
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}>
      <Text color={TextColor.GreyDark} type={TextType.Middle20} isExtraBold>
        {t("ContactUs.Title")}
      </Text>

      <Spacer top='18px' />

      <Input
        title={{ text: t("ContactUs.Input.Name.Title") }}
        placeholder={t("ContactUs.Input.Name.Placeholder")}
        className='modal-input'
        value={inputs.name}
        onChange={(v) => onChangeInput(v, "name")}
      />

      <Spacer top='18px' />

      <Input
        title={{ text: t("ContactUs.Input.Email.Title"), className: "modal-input-req" }}
        placeholder={t("ContactUs.Input.Email.Placeholder")}
        className='modal-input'
        value={inputs.email}
        onChange={(v) => onChangeInput(v, "email")}
        type={"email"}
        required
      />

      <Spacer top='18px' />

      <div className='modal-textarea-wrapper'>
        <Text color={TextColor.GreyDark} className='modal-textarea-title'>
          {t("ContactUs.Input.Message.Title")}
        </Text>

        <textarea
          placeholder={t("ContactUs.Input.Message.Placeholder")}
          className='modal-textarea'
          value={inputs.message}
          onChange={(e) => onChangeInput(e.target.value, "message")}
          rows={8}
          required
        />
      </div>

      <Spacer top='44px' />

      <div className='modal-agreement'>
        <input
          type={"checkbox"}
          className='modal-agreement-checkbox'
          required
          value={inputs.ppagreement ? "true" : "false"}
          onClick={() => onChangeInput(!inputs.ppagreement, "ppagreement")}
        />

        <div>
          <Text>
            {t("ContactUs.PPAgreement")}
            <Link
              target={"_blank"}
              to={AppRoutingPath.PrivacyPolicy}
              className='modal-link'>
              {t("ContactUs.PPAgreementLink")}
            </Link>
          </Text>
        </div>
      </div>

      <Spacer top='44px' />

      <Button
        type={ButtonType.Secondary}
        btnType='submit'
        className={`modal-btn now${!isSubmitAvailable ? " disabled" : ""}`}
        disabled={!isSubmitAvailable}>
        <Text isExtraBold color={TextColor.Triple3}>
          {t("Btn.SendMessage")}
        </Text>
      </Button>

      <Icon
        type={IconType.Cross}
        className={"modal-close on-hover"}
        size='14px '
        onClick={() => {
          onCloseModal();
        }}
      />
    </form>
  );
};

export default ModalContactUs;
