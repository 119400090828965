import React, { useCallback, useMemo, useState } from "react";
import Button, { ButtonType } from "../../common/Button";
import Icon, { IconColor, IconType } from "../../common/Icon";
import Spacer from "../../common/Spacer";
import Text, { TextColor, TextFont, TextType } from "../../common/Text";
import useLangTranslation, {
  TranslationText,
} from "../../shared/hooks/useLangTranslation";

import "./Pricing.scss";
import { PricingCardPeriodType, PricingCardPlanName } from "./types";
import HumanPricing from "../../assets/Human_pricing.svg";

interface PricingCardProps {
  price?: number;
  name: PricingCardPlanName;
  period: PricingCardPeriodType;
  btnText: string;
}

const PricingCard: React.FC<PricingCardProps> = ({ price, name, period, btnText }) => {
  const [t] = useLangTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const textColor = isHovered ? TextColor.White : TextColor.Triple3;

  return (
    <div
      className={"pricing-card-container on-hover"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <Text
        isCenter
        font={TextFont.Montserrat}
        type={TextType.Middle20}
        color={textColor}>
        {t(`Section8.Type.${name}` as TranslationText.Section8TypeFree)}
      </Text>

      <div className='pricing-card-price-wrapper'>
        {typeof price === "number" ? (
          <>
            <Text
              isBold
              font={TextFont.Montserrat}
              color={textColor}
              type={TextType.Middle20}
              lineHeight='32px'
              style={{ marginRight: "4px" }}>
              $
            </Text>

            <Text
              font={TextFont.Montserrat}
              type={TextType.Large48}
              color={textColor}
              lineHeight='40px'
              fontWeight={600}
              style={{
                paddingTop: isHovered ? "5px" : "13px",
                transition: "padding-top 0.35s ease-in-out",
              }}>
              {price}
            </Text>
          </>
        ) : (
          <Text
            isBold
            font={TextFont.Montserrat}
            color={textColor}
            lineHeight='32px'
            style={{
              paddingTop: isHovered ? 0 : "7px",
              transition: "padding-top 0.35s ease-in-out",
            }}>
            {t(TranslationText.Section8Quote)}
          </Text>
        )}
      </div>

      <Text
        isCenter
        color={textColor}
        font={TextFont.Montserrat}
        fontSize='14px'
        lineHeight={"20px"}>
        {t(TranslationText.Section8PeriodPer) +
          " " +
          t(`Section8.Period.${period}` as TranslationText.Section8PeriodMonth)}
      </Text>

      <Spacer top='18px' />

      <Button
        type={isHovered ? ButtonType.Primary : ButtonType.DarkGrey}
        isTool
        className='on-hover'>
        <Text
          isBold
          isCenter
          noWrap
          font={TextFont.Montserrat}
          className='on-hover'
          color={isHovered ? TextColor.Triple3 : TextColor.White}>
          {btnText}
        </Text>
      </Button>

      <div className={`pricing-card-selected ${isHovered ? "active" : ""}`} />
    </div>
  );
};

const enum PricingTableColType {
  Text = 0,
  Checkbox = 1,
  CheckboxAndText = 2,
  Empty = 3,
}

interface PricingTableCol {
  type: PricingTableColType;
  data?: string | null;
}

interface PricingTableRowProps {
  isHighlighted: boolean;
  title: string;
	cols: PricingTableCol[];
	activeColumn?: number
}

const PricingTableRow: React.FC<PricingTableRowProps> = ({
  isHighlighted,
  title,
	cols,
	activeColumn = null
}) => {
  const __colTypeToElement = useCallback(
    (col: PricingTableCol) =>
      [
        <Text
          isBold
          isCenter
          font={TextFont.Montserrat}
          color={TextColor.GreyDark}
          lineHeight='40px'>
          {col.data}
        </Text>,
        <Icon type={IconType.Check} size='20px' />,
        <>
          <Icon type={IconType.Check} size='20px' />
          <Text
            color={TextColor.GreyDark}
            font={TextFont.Montserrat}
            isBold
            fontSize='14px'
            lineHeight='24px'>
            {col.data}
          </Text>
        </>,
        null,
      ][col.type],
    []
  );

  return (
    <div className={`pricing-table-row ${isHighlighted ? "hg" : ""}`}>
      <Text
        className='pricing-table-col-title'
        noWrap
        isBold
        color={TextColor.GreyDark}
        font={TextFont.Montserrat}
        lineHeight='40px'>
        {title}
      </Text>

      <div className='pricing-table-col-wrapper'>
        {activeColumn === null ? cols.map((c, idx) => (
          <div
            key={`${title}-${idx}`}
            className={`pricing-table-col pricing-table-col-${idx + 1}`}>
            {__colTypeToElement(c)}
          </div>
        )) : <div
						key={`${title}-${activeColumn}`}
						className={`pricing-table-col pricing-table-col-${(activeColumn) + 1}`}>
						{__colTypeToElement(cols[activeColumn])}
					</div>}
      </div>
    </div>
  );
};

export const PricingTable = ({ activeColumn }: { activeColumn?: number }) => {
  const [t] = useLangTranslation();

  const rows = useMemo(() => {
    const __rows = t(TranslationText.Section8PlanChanges, { returnObjects: true });

    if (__rows === TranslationText.Section8PlanChanges) {
      return [];
    }

    return __rows as unknown as Omit<PricingTableRowProps, "isHighlighted">[];
  }, [t]);

  return (
    <div className='pricing-table'>
      {rows.map((r, idx) => (
        <PricingTableRow
          {...r}
          isHighlighted={!!((idx + 1) % 2)}
					key={`ptc-${idx}-ccc`}
					activeColumn={activeColumn}
        />
      ))}
    </div>
  );
};

interface PricingDesktopProps {
  isYearly: boolean;
  switchPaymentPeriod: (p: PricingCardPeriodType) => void;
  paymentPeriod: PricingCardPeriodType;
}

const PricingDesktop: React.FC<PricingDesktopProps> = ({
  isYearly,
  switchPaymentPeriod,
  paymentPeriod,
}) => {
  const [t] = useLangTranslation();

  return (
    <div className='pricing-table-container'>
      <div className='pricing-table-plans-container'>
        <div className='pricing-table-plans-switcher-container'>
          <div className='pricing-table-plans-switcher'>
            <div className={`active-opt ${isYearly ? "yearly" : "monthly"}`} />

            <div
              className={`pricing-table-plans-discount-container ${
                isYearly ? "active" : ""
              }`}>
              <Text
                color={TextColor.Grey}
                font={TextFont.Montserrat}
                className='pricing-table-plans-discount-text'
                fontSize='14px'
                lineHeight='20px'>
                {t(TranslationText.Section8Discount)}
              </Text>

              <Icon
                type={IconType.ThumbUp}
                color={IconColor.None}
                size='24px'
                className='pricing-table-plans-discount-ico'
              />
            </div>

            <Text
              isBold
              onClick={(e) => {
                e.preventDefault();
                switchPaymentPeriod(PricingCardPeriodType.Monthly);
              }}
              font={TextFont.Montserrat}
              className={"pricing-table-plans-switcher-opt"}
              style={{
                color: !isYearly ? "#fff" : "rgba(0,0,0,0.35)",
              }}>
              {t(TranslationText.Section8PeriodMonthly)}
            </Text>

            <Spacer left='38px' />

            <Text
              isBold
              onClick={(e) => {
                e.preventDefault();
                switchPaymentPeriod(PricingCardPeriodType.Yearly);
              }}
              font={TextFont.Montserrat}
              className={"pricing-table-plans-switcher-opt"}
              style={{
                color: isYearly ? "#fff" : "rgba(0,0,0,0.35)",
              }}>
              {t(TranslationText.Section8PeriodYearly)}
            </Text>
          </div>
        </div>

        <div className='pricing-table-plans-container'>
          <PricingCard
            price={0}
            name={PricingCardPlanName.Free}
            period={paymentPeriod}
            btnText={t(TranslationText.CommonTry4Free)}
          />

          <PricingCard
            price={isYearly ? 50 : 5}
            name={PricingCardPlanName.Business}
            period={paymentPeriod}
            btnText={t(TranslationText.CommonGetDemo)}
          />

          <PricingCard
            name={PricingCardPlanName.Enterprise}
            period={paymentPeriod}
            btnText={t(TranslationText.CommonGetDemo)}
          />
        </div>
      </div>

      <Spacer top='30px' />

      <PricingTable />

      <img src={HumanPricing} alt='HumanPricing' className='pricing-table-img' />
    </div>
  );
};

export default PricingDesktop;
