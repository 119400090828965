import React, { useMemo } from "react";

const __selflink = "/privacy-policy";
const __selflink_pp = __selflink + "/privacy-policy";
const __selflink_tou = __selflink + "/terms-of-use";

interface PrivacyPolicyProps {
  link?: string | null;
  linkText?: string | null;
}

const __defaultLink = "mailto:support@ustork.com.ua?object=U.Stork Privacy Policy";
const __defaultLinkText = "support@ustork.com.ua";

const PrivacyPolicyLink: React.VFC<PrivacyPolicyProps> = ({
  link = __defaultLink,
  linkText = __defaultLinkText,
}) => {
  const __link = useMemo(() => {
    if (link === "__selflink_pp") {
      return __selflink_pp;
    } else if (link === "__selflink_tou") {
      return __selflink_tou;
    } else {
      return link || __defaultLink;
    }
  }, [link]);

  return (
    <a className='link' href={__link} target={"_blank"} rel='noreferrer'>
      {linkText || __defaultLinkText}
    </a>
  );
};

export default PrivacyPolicyLink;
