import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import appReducer from "./app.slice";

export const rootReducer = combineReducers({
  app: appReducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === "development",
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
