import React from "react";

import BlockTitle from "../../common/BlockTitle";
import Spacer from "../../common/Spacer";
import Text, { TextColor, TextFont } from "../../common/Text";
import Icon, { IconColor, IconType } from "../../common/Icon";

import useLangTranslation, {
  TranslationText,
} from "../../shared/hooks/useLangTranslation";

import Human from "../../assets/HumanLeft.svg";

import "./AboutUs.scss";
import Footer from "../Footer";

const AboutUs: React.FC = () => {
  const [t] = useLangTranslation();

  return (
    <div className='about-container'>
      <div className='d-flex f-col about-text'>
        <BlockTitle text={t(TranslationText.Section9Title)} />

        <Spacer top='30px' />

        <Text color={TextColor.Black} font={TextFont.Montserrat} lineHeight='40px'>
          {t(TranslationText.Section9Subtitle1)}
        </Text>

        <Spacer top='25px' />

        <Text color={TextColor.Black} font={TextFont.Montserrat} lineHeight='40px'>
          {t(TranslationText.Section9Subtitle2)}
        </Text>
      </div>

      <Icon
        type={IconType.ArrowLongLBRT}
        color={IconColor.Accent}
        size='27px'
        className='about-ico about-ico-1'
      />
      <Icon
        type={IconType.Search}
        color={IconColor.Accent}
        size='27px'
        className='about-ico about-ico-2'
      />
      <Icon
        type={IconType.WebLink}
        color={IconColor.Accent}
        size='28px'
        className='about-ico about-ico-3'
      />

      <Icon
        type={IconType.ArrowRightReverse}
        color={IconColor.Accent}
        size='29px'
        className='about-ico about-ico-4'
      />
      <Icon
        type={IconType.User}
        color={IconColor.Accent}
        size='28px'
        className='about-ico about-ico-5'
      />
      <Icon
        type={IconType.InfoShow}
        color={IconColor.Accent}
        size='35px'
        className='about-ico about-ico-6'
      />
      <Icon
        type={IconType.Clock}
        color={IconColor.Accent}
        size='32px'
        className='about-ico about-ico-7'
      />
      <Icon
        type={IconType.Plus}
        color={IconColor.Accent}
        size='29px'
        className='about-ico about-ico-8'
      />

      <img src={Human} alt='Human' className='about-img about-img-1' />

      {/* <Footer /> */}
    </div>
  );
};

export default AboutUs;
