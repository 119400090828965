import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export enum TranslationLanguages {
  Ukrainian = "ua",
  English = "en",
}

export enum TranslationText {
  HeaderWhy = "Header.Why",
  HeaderProduct = "Header.Product",
  HeaderFeatures = "Header.Features",
  HeaderHowItWorks = "Header.HowItWorks",
  HeaderProductTour = "Header.ProductTour",
  HeaderPricing = "Header.Pricing",
  HeaderAboutUs = "Header.AboutUs",
  HeaderContact = "Header.Contact",

  Section1Title1 = "Section1.Title.1",
  Section1Title2 = "Section1.Title.2",
  Section1TitleSub = "Section1.Title.Sub",

  Section2Title = "Section2.Title",
  Section2Human1Thoughts = "Section2.Human1Thoughts",
  Section2Human2Thoughts = "Section2.Human2Thoughts",
  Section2Human3Thoughts = "Section2.Human3Thoughts",

  Section3Title = "Section3.Title",
  Section3Subtitle1 = "Section3.Subtitle1",
  Section3Subtitle2 = "Section3.Subtitle2",
  Section3StagesTitle = "Section3.Stages.Title",
  Section3Stages1 = "Section3.Stages.1",
  Section3Stages2 = "Section3.Stages.2",
  Section3Stages3 = "Section3.Stages.3",
  Section3Stages4 = "Section3.Stages.4",

  Section4Title = "Section4.Title",
  Section4Subtitle = "Section4.Subtitle",
  Section4Goals1Title = "Section4.Goals.1.Title",
  Section4Goals1Subtitle = "Section4.Goals.1.Subtitle",
  Section4Goals2Title = "Section4.Goals.2.Title",
  Section4Goals2Subtitle = "Section4.Goals.2.Subtitle",
  Section4Goals3Title = "Section4.Goals.3.Title",
  Section4Goals3Subtitle = "Section4.Goals.3.Subtitle",

  Section5Title = "Section5.Title",
  Section5IETitle = "Section5.IntelligentEditor.Title",
  Section5IESubtitle = "Section5.IntelligentEditor.Subtitle",
  Section5PFTitle = "Section5.PresentationFrame.Title",
  Section5PFSubtitle = "Section5.PresentationFrame.Subtitle",
  Section5EPTitle = "Section5.EvaluationProcess.Title",
  Section5EPSubtitle = "Section5.EvaluationProcess.Subtitle",
  Section5GDICTitle = "Section5.GoalDriven.Title",
  Section5GDICSubtitle = "Section5.GoalDriven.Subtitle",
  Section5UCLTitle = "Section5.UseCases.Title",
  Section5UCLSubtitle = "Section5.UseCases.Subtitle",

  Section6Title = "Section6.Title",
  Section6Cards = "Section6.Cards",

  Section7Title = "Section7.Title",

  Section8Title = "Section8.Title",
  Section8Discount = "Section8.Discount",
  Section8PeriodPer = "Section8.Period.Per",
  Section8PeriodMonthly = "Section8.Period.Monthly",
  Section8PeriodYearly = "Section8.Period.Yearly",
  Section8PeriodMonth = "Section8.Period.month",
  Section8PeriodYear = "Section8.Period.year",
  Section8TypeFree = "Section8.Type.Free",
  Section8TypeBusiness = "Section8.Type.Business",
  Section8TypeEnterprise = "Section8.Type.Enterprise",
  Section8Quote = "Section8.Quote",
  Section8PlanChanges = "Section8.PlanChanges",

  Section9Title = "Section9.Title",
  Section9Subtitle1 = "Section9.Subtitle1",
  Section9Subtitle2 = "Section9.Subtitle2",

  FooterContact = "Footer.Contact",
  FooterPrivacyPolicy = "Footer.PrivacyPolicy",
  FooterDataSecurity = "Footer.DataSecurity",
  FooterTermsOfUse = "Footer.TermsOfUse",
  FooterRights = "Footer.Rights",

  DocumentNavigation = "Navigation",
  DocumentText = "Text",

  CommonTry4Free = "Common.Try4Free",
  CommonGetDemo = "Common.GetDemo",
}

export const enum TranslationNamespace {
  Landing = "landing",
  PrivacyPolicy = "privacy_policy",
  DataSecurity = "data_security",
  TermsOfUse = "terms_of_use",
  Modals = "modals",
}

const useLangTranslation = (
  ns: string = TranslationNamespace.Landing
): [
  (
    text: TranslationText | string,
    options?: { [key: string]: any; returnObjects?: boolean } | string
  ) => string,
  (lang: TranslationLanguages) => void,
  string
] => {
  const { t, i18n } = useTranslation(ns);

  const changeLanguage = useCallback(
    (lang: TranslationLanguages) => i18n.changeLanguage(lang),
    [i18n]
  );

  return [t, changeLanguage, i18n.language];
};

export default useLangTranslation;
