/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import { useNavigate } from "react-router";

import { AppRoutingPath } from "../../App";
import Button, { ButtonType } from "../../common/Button";
import Icon, { IconColor, IconType } from "../../common/Icon";
import Text, { TextColor, TextFont, TextType } from "../../common/Text";
import useAdaptiveLayout from "../../shared/hooks/useAdaptiveLayout";
import useAppModal from "../../shared/hooks/useAppModal";

import useLangTranslation, {
  TranslationText,
} from "../../shared/hooks/useLangTranslation";
import ModalContactUs from "../ModalContactUs";

import "./Footer.scss";

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const [t] = useLangTranslation();
  const { onOpenModal, onCloseModal, isModalOpen } = useAppModal();

  const onChangePage = useCallback((r: AppRoutingPath) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setTimeout(() => {
      navigate(r);
    }, 800);
  }, []);

  const onChangeNavigation = useCallback(
    (route: AppRoutingPath) => {
      if (isModalOpen) {
        return;
      }
      navigate(route, { replace: window.location.pathname === AppRoutingPath.Home });
    },
    [isModalOpen, navigate]
	);
	const adaptiveProps = useAdaptiveLayout({
		1000: {
			logoWidth: "80px",
			logoHeight: "20px"
		},
		0: {
			logoWidth: "132px",
			logoHeight: "30px"
		}
	})

  return (
    <footer className='footer-container'>
      <div className='footer-content-container'>
        <Icon
					type={IconType.UStork}
					color={IconColor.White}
					width={adaptiveProps.logoWidth}
					height={adaptiveProps.logoHeight}
        />

        <div className='footer-content-links'>
          <Text
            noWrap
            color={TextColor.White}
            fontSize='18px'
            lineHeight='26px'
            fontWeight={600}
            onClick={() => {
              onChangeNavigation(AppRoutingPath.HomeWhy);
            }}>
            {t(TranslationText.Section3Title)}
          </Text>

          <div className='d-flex f-col' style={{ gap: "16px" }}>
            <Text
              noWrap
              color={TextColor.White}
              fontSize='18px'
              lineHeight='26px'
              fontWeight={600}
              onClick={() => {
                onChangeNavigation(AppRoutingPath.HomeProduct);
              }}>
              {t(TranslationText.Section4Title)}
            </Text>

            <Text
              noWrap
              color={TextColor.White}
              lineHeight='26px'
              onClick={() => {
                onChangeNavigation(AppRoutingPath.HomeProductFeatures);
              }}>
              {t(TranslationText.Section5Title)}
            </Text>
            <Text
              noWrap
              color={TextColor.White}
              lineHeight='26px'
              onClick={() => {
                onChangeNavigation(AppRoutingPath.HomeProductHow);
              }}>
              {t(TranslationText.Section6Title)}
            </Text>
            <Text
              noWrap
              color={TextColor.White}
              lineHeight='26px'
              onClick={() => {
                onChangeNavigation(AppRoutingPath.HomeProductTour);
              }}>
              {t(TranslationText.Section7Title)}
            </Text>
          </div>

          <Text
            noWrap
            color={TextColor.White}
            fontSize='18px'
            lineHeight='26px'
            fontWeight={600}
            onClick={() => {
              onChangeNavigation(AppRoutingPath.HomePricing);
            }}>
            {t(TranslationText.Section8Title)}
          </Text>

          <div className='d-flex f-col' style={{ gap: "16px" }}>
            <Text
              noWrap
              color={TextColor.White}
              fontSize='18px'
              lineHeight='26px'
              fontWeight={600}
              onClick={() => {
                onChangeNavigation(AppRoutingPath.HomeAbout);
              }}>
              {t(TranslationText.Section9Title)}
            </Text>

            <Text
              noWrap
              color={TextColor.White}
              lineHeight='26px'
              className='footer-content-links-clickable'
              onClick={(e) => {
                e.preventDefault();
                onChangePage(AppRoutingPath.PrivacyPolicy);
              }}>
              {t(TranslationText.FooterPrivacyPolicy)}
            </Text>

            <Text
              noWrap
              color={TextColor.White}
              lineHeight='26px'
              className='footer-content-links-clickable'
              onClick={(e) => {
                e.preventDefault();
                onChangePage(AppRoutingPath.DataSecurity);
              }}>
              {t(TranslationText.FooterDataSecurity)}
            </Text>

            <Text
              noWrap
              color={TextColor.White}
              lineHeight='26px'
              className='footer-content-links-clickable'
              onClick={(e) => {
                e.preventDefault();
                onChangePage(AppRoutingPath.TermsOfUse);
              }}>
              {t(TranslationText.FooterTermsOfUse)}
            </Text>
          </div>
        </div>

        <div className='footer-content-contacts'>
          <Button
            type={ButtonType.Secondary}
            style={{ width: "100%" }}
            onClick={() => {
              onOpenModal({
                content: () => <ModalContactUs onCloseModal={onCloseModal} />,
                className: "modal-wrapper",
              });
            }}>
            <Text font={TextFont.Montserrat} color={TextColor.Triple3} isBold>
              {t(TranslationText.FooterContact)}
            </Text>
          </Button>

          <div className='footer-content-contacts-links'>
            <a
              className='footer-content-links-clickable'
              href='https://www.linkedin.com/company/u-stork-lab/'
              target='_blank'
              rel='noreferrer'>
              <Icon
                color={IconColor.White}
                type={IconType.SocialLinkedIn}
                size='40px'
                className='footer-content-links-clickable-child'
              />
            </a>

            <a
              className='footer-content-links-clickable'
              href='https://instagram.com/ustorkua/'
              target='_blank'
              rel='noreferrer'>
              <Icon
                color={IconColor.White}
                type={IconType.SocialInstagram}
                size='40px'
                className='footer-content-links-clickable-child'
              />
            </a>
            <a
              className='footer-content-links-clickable'
              href='https://www.facebook.com/ustork/'
              target='_blank'
              rel='noreferrer'>
              <Icon
                color={IconColor.White}
                type={IconType.SocialFacebook}
                size='40px'
                className='footer-content-links-clickable-child'
              />
            </a>
          </div>
        </div>
      </div>

      <Text isCenter type={TextType.Caption} color={TextColor.Light2} lineHeight='35px'>
        {t(TranslationText.FooterRights)}
      </Text>
    </footer>
  );
};

export default Footer;
