import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
export interface AppModalConfig {
  content: React.FC;
  className?: string;
}

export type AppModal = AppModalConfig | null | undefined;
export const AppModalDefault: AppModal = null;

interface AppState {
  modal: AppModal;
}
const initialState: AppState = {
  modal: AppModalDefault,
};

const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    appModalOpen: (state: AppState, action: PayloadAction<AppModalConfig>) => {
      state.modal = action.payload ?? AppModalDefault;
    },
    appModalClose: (state: AppState) => {
      state.modal = null;
    },
  },
});

export default app.reducer;

export const { appModalOpen, appModalClose } = app.actions;

export const selectAppModal = createSelector(
  (state: RootState) => state.app,
  (app) => app.modal
);
