import { useState } from 'react'
import useLangTranslation, { TranslationText } from "../../shared/hooks/useLangTranslation";
import Spacer from "../../common/Spacer";
import Text, { TextColor, TextFont, TextType } from "../../common/Text";
import Button, { ButtonType } from "../../common/Button";

export const Card = ({ price, name, period, btnText }) => {
	const [t] = useLangTranslation();
	const [isHovered, setIsHovered] = useState(false);
	const textColor = isHovered ? TextColor.White : TextColor.Triple3;

	return (
		<div
			className={"pricing-card-container on-hover"}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}>
			<Text
				isCenter
				font={TextFont.Montserrat}
				type={TextType.Middle20}
				color={textColor}>
				{t(`Section8.Type.${name}`)}
			</Text>

			<div className='pricing-card-price-wrapper'>
				{typeof price === "number" ? (
					<>
						<Text
							isBold
							font={TextFont.Montserrat}
							color={textColor}
							type={TextType.Middle20}
							lineHeight='32px'
							style={{ marginRight: "4px" }}>
							$
						</Text>

						<Text
							font={TextFont.Montserrat}
							type={TextType.Large48}
							color={textColor}
							lineHeight='40px'
							fontWeight={600}
							style={{
								paddingTop: isHovered ? "5px" : "13px",
								transition: "padding-top 0.35s ease-in-out",
							}}>
							{price}
						</Text>
					</>
				) : (
					<Text
						isBold
						font={TextFont.Montserrat}
						color={textColor}
						lineHeight='32px'
						style={{
							paddingTop: isHovered ? 0 : "7px",
							transition: "padding-top 0.35s ease-in-out",
						}}>
						{t(TranslationText.Section8Quote)}
					</Text>
				)}
			</div>

			<Text
				isCenter
				color={textColor}
				font={TextFont.Montserrat}
				fontSize='14px'
				lineHeight={"20px"}>
				{t(TranslationText.Section8PeriodPer) +
					" " +
					t(`Section8.Period.${period}`)}
			</Text>

			<Spacer top='18px' />

			<Button
				type={isHovered ? ButtonType.Primary : ButtonType.DarkGrey}
				isTool
				className='on-hover'>
				<Text
					isBold
					isCenter
					noWrap
					font={TextFont.Montserrat}
					className='on-hover'
					color={isHovered ? TextColor.Triple3 : TextColor.White}>
					{btnText}
				</Text>
			</Button>

			<div className={`pricing-card-selected ${isHovered ? "active" : ""}`} />
		</div>)
}

export const MobileCard = ({ price, name, period, btnText, onClick, isActive, isHidden }) => {
	const [t] = useLangTranslation();

	return (
		<div className={`pricing-card-mobile-container ${isHidden ? 'hidden' : ''}`}>
			<div className="pricing-card-mobile-column">
				<Text
					isCenter
					font={TextFont.Montserrat}
					type={TextType.Middle20}
					color={TextColor.Triple3}>
					{t(`Section8.Type.${name}`)}
				</Text>
				<Button
					width='100%'
					type={ButtonType.DarkGrey}
					isTool
					className='on-hover'>
					<Text
						isBold
						isCenter
						noWrap
						font={TextFont.Montserrat}
						className='on-hover'
						color={TextColor.White}>
						{btnText}
					</Text>
				</Button>
			</div>

			<div className="pricing-card-mobile-column">
				<Text
					isCenter
					color={TextColor.Triple3}
					font={TextFont.Montserrat}
					fontSize='14px'
					lineHeight={"20px"}>
					{t(TranslationText.Section8PeriodPer) +
						" " +
						t(`Section8.Period.${period}`)}
				</Text>
				<div className='pricing-card-mobile-price-wrapper'>
					{typeof price === "number" ? (
						<>
							<Text
								isBold
								font={TextFont.Montserrat}
								color={TextColor.Triple3}
								type={TextType.Middle}
								lineHeight='40px'>
								$
							</Text>

							<Text
								font={TextFont.Montserrat}
								type={TextType.Large}
								color={TextColor.Triple3}
								lineHeight='40px'
								fontWeight={600}>
								{price}
							</Text>
						</>
					) : (
						<Text
							isBold
							font={TextFont.Montserrat}
							color={TextColor.Triple3}
							lineHeight='32px'
						>
							{t(TranslationText.Section8Quote)}
						</Text>
					)}
				</div>
			</div>

			<div className={`pricing-card-mobile-collapse ${isActive ? 'collapsed' : ''}`} onClick={onClick}>
				<ArrowIcon />
			</div>
		</div>)
}

const ArrowIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fillRule="evenodd" clipRule="evenodd" d="M4.42268 9.01007C4.01402 8.63856 3.38157 8.66868 3.01007 9.07733C2.63856 9.48599 2.66868 10.1184 3.07733 10.4899L11.3273 17.9899C11.7088 18.3367 12.2913 18.3367 12.6727 17.9899L20.9227 10.4899C21.3313 10.1184 21.3615 9.48599 20.9899 9.07733C20.6184 8.66868 19.986 8.63856 19.5773 9.01007L12 15.8985L4.42268 9.01007Z" fill="black" />
</svg>


export const Table = () => {

}