import React, { CSSProperties } from "react";
import styled from "styled-components";

export const enum TextType {
  Caption = "12px",
  Small = "16px",
  Middle = "24px",
  Middle20 = "20px",
  Large = "32px",
  Large38 = "38px",
  Large40 = "40px",
  Large48 = "48px",
  H1 = "90px",
  H2 = "64px",
  H3 = "50px",
  H4 = "40px",
}

export const enum TextColor {
  Primary = "primary",
  BananaDark = "#c69f06",
  Red = "#E53900",
  Secondary = "rgba(0, 0, 0, 0.1)",
  Black = "#000000",
  Triple3 = "#333",
  Grey = "rgba(0, 0, 0, 0.5)",
  GreyDark = "rgba(0, 0, 0, 0.8)",
  GreyLight = "rgba(0, 0, 0, 0.2)",
  Light8 = "rgba(255, 255, 255, 0.8)",
  Light2 = "rgba(255, 255, 255, 0.2)",
  White = "#FFFFFF",
  Link = "#4A91DB",
}

export const enum TextFont {
  Roboto = "Roboto",
  Montserrat = "Montserrat",
  MontserratAlternates = "Montserrat Alternates",
}

interface TextStyledProps {
  accentColor: string;
  color: TextColor;
  font?: TextFont;
  type?: TextType;
  fontSize?: CSSProperties["fontSize"];
  lineHeight?: CSSProperties["lineHeight"];
  noWrap?: boolean;
  isHardTextWrap?: boolean;
  isInvert?: boolean;
  isCenter?: boolean;
  isRight?: boolean;
  fontWeight?: CSSProperties["fontWeight"];
  isExtraBold?: boolean;
  isBold?: boolean;
  isPointer?: boolean;
  isInline?: boolean;
  isUpperCase?: boolean;
  isLink?: boolean;
  width?: CSSProperties["width"];
}

const TextStyled = styled.p<TextStyledProps>`
  margin: 0;
  padding: 0;
  height: fit-content;
  width: ${({ width }) => width ?? "fit-content"};
  transition: all 0.35s ease-in-out;
  user-select: none;

  text-decoration: none;
  &::hover {
    text-decoration: ${({ isLink }) => (isLink ? "underline" : "none")};
  }

  display: ${({ isInline }) => (isInline ? "inline" : "block")};
  color: ${({ color, accentColor }) =>
    color === TextColor.Primary ? accentColor : color};

  text-align: ${({ isCenter, isRight }) =>
    isCenter ? "center" : isRight ? "right" : "left"};
  word-break: ${({ noWrap, isHardTextWrap }) =>
    noWrap ? "nowrap" : isHardTextWrap ? "break-all" : "wrap"};
  white-space: ${({ noWrap }) => (noWrap ? "nowrap" : "wrap")};
  flex-wrap: ${({ noWrap }) => (noWrap ? "nowrap" : "wrap")};
  font-family: ${({ font }) => `"${font}", sans-serif`};
  font-weight: ${({ isExtraBold, isBold, fontWeight }) =>
    fontWeight ? fontWeight : isExtraBold ? 700 : isBold ? 500 : "inherit"};
  font-size: ${({ type, fontSize }) => (fontSize ? fontSize : type || TextType.Small)};
  line-height: ${({ lineHeight }) => lineHeight || "130%"};

  cursor: ${({ isPointer }) => (isPointer ? "pointer" : "normal")};
  text-transform: ${({ isUpperCase }) => (isUpperCase ? "uppercase" : "none")};

  &[data-index] {
    &::before {
      content: attr(data-index);
      margin-right: 5px;
    }
  }
`;

export interface TextProps
  extends Omit<TextStyledProps, "color" | "accentColor" | "schema"> {
  className?: string;
  children?: React.ReactNode;
  text?: string | null;
  textRef?: React.Ref<HTMLParagraphElement>;
  color?: TextColor;
  onClick?: React.MouseEventHandler<HTMLDivElement>;

  style?: CSSProperties;
  index?: string | number | null;
}

const Text: React.FC<TextProps> = ({
  className = "",
  children,
  text = null,
  textRef = null,
  type,
  color = TextColor.Grey,
  onClick = null,
  font = TextFont.Roboto,
  style = {},
  isLink,
  isBold,
  isExtraBold,
  isCenter,
  isRight,
  isPointer,
  isInline,
  isInvert,
  isUpperCase,
  isHardTextWrap,
  fontWeight,
  fontSize,
  lineHeight,
  noWrap,
  width,
  index,
}) => {
  return (
    <TextStyled
      data-index={index}
      ref={textRef}
      accentColor={"#FFCC00"}
      onClick={onClick ? onClick : () => {}}
      className={className ?? ""}
      type={type}
      isLink={isLink}
      font={font}
      color={color ?? TextColor.Grey}
      noWrap={noWrap}
      isHardTextWrap={isHardTextWrap}
      isRight={isRight}
      isCenter={isCenter}
      isExtraBold={isExtraBold}
      isBold={isBold}
      isInline={isInline}
      isPointer={isPointer || !!onClick}
      isUpperCase={isUpperCase}
      isInvert={isInvert}
      fontWeight={fontWeight}
      fontSize={fontSize}
      lineHeight={lineHeight}
      style={style ? { ...style } : {}}
      width={width}>
      {text ?? children}
    </TextStyled>
  );
};

export default Text;
