import Slider from 'react-slick'

import Text, { TextColor, TextFont, TextType } from "../../common/Text";
import Spacer from "../../common/Spacer";

import { AssistsEmployeesImg, HelpsLeadersImg,AllowsCompaniesImg} from "./SliderImages";
import useLangTranslation, {
  TranslationText,
} from "../../shared/hooks/useLangTranslation";


import "./ProductCarousel.scss"

const ProductSlider = () => {
	const [t, , lang] = useLangTranslation();

	const settings = {
		speed: 300,
		slidesToShow: 3,
		centerMode: false,
		adaptiveHeight: true,
    responsive: [
			{
        breakpoint: 1200,
				settings: {
					infinite: true,
					autoplay: true,
					autoplaySpeed: 2000,
					slidesToShow: 2,
        }
      },
      {
        breakpoint: 900,
				settings: {
					infinite: true,
					autoplay: true,
					autoplaySpeed: 2000,
					slidesToShow: 1,
        }
      }
    ]
  };
  return (
		<Slider {...settings} className="product-carousel">
			<div className="product-carousel-img aei img-non-selectable">
				<div className="product-carousel-img__inner">
					<AssistsEmployeesImg />
					<div className="product-carousel-content">
						<div className="product-carousel-content__title">
							{t(TranslationText.Section4Goals1Title)}
						</div>
						<div className="product-carousel-content__text">
							{t(TranslationText.Section4Goals1Subtitle)}
						</div>
					</div>
				</div>
			</div>

			<div className="product-carousel-img hli img-non-selectable">
				<div className="product-carousel-img__inner">
					<HelpsLeadersImg />
					<div className="product-carousel-content">
						<div className="product-carousel-content__title">
							{t(TranslationText.Section4Goals2Title)}
						</div>
						<div className="product-carousel-content__text">
							{t(TranslationText.Section4Goals2Subtitle)}
						</div>
					</div>
				</div>
			</div>

			<div className="product-carousel-img aci img-non-selectable">
				<div className="product-carousel-img__inner">
					<AllowsCompaniesImg />
					<div className="product-carousel-content">
						<div className="product-carousel-content__title">
							{t(TranslationText.Section4Goals3Title)}
						</div>
						<div className="product-carousel-content__text">
							{t(TranslationText.Section4Goals3Subtitle)}
						</div>
					</div>
				</div>
			</div>
		</Slider>
  );
}

export default ProductSlider




