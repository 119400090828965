import React from "react";
import Icon, { IconColor, IconType } from "../../common/Icon";
import Text, { TextColor, TextFont, TextType } from "../../common/Text";
// import Spacer from "../../common/Spacer";
import BlockTitle from "../../common/BlockTitle";

// import AssistsEmployeesImg from "../../assets/Assists_employees.svg";
// import HelpsLeadersImg from "../../assets/Helps_leaders.svg";
// import AllowsCompaniesImg from "../../assets/Allows_companies.svg";
import useLangTranslation, {
  TranslationText,
} from "../../shared/hooks/useLangTranslation";

import "./Product.scss";

import ProductCarousel from "../ProductCarousel";

const Product: React.FC = () => {
  const [t, , lang] = useLangTranslation();

  return (
    <div className='product-container'>
      <div className='d-flex f-col product-text'>
        <BlockTitle text={t(TranslationText.Section4Title)} />

        <Text color={TextColor.Triple3} font={TextFont.Montserrat}>
          {t(TranslationText.Section4Subtitle)}
        </Text>
      </div>
			
			<ProductCarousel/>
      
			<div className='product-bg'>
        <Icon
          type={IconType.Clock}
          color={IconColor.Accent}
          size='32px'
          className='product-bg-icons product-bg-icons-1'
        />
        <Icon
          type={IconType.ArrowRightReverse}
          color={IconColor.Accent}
          size='28px'
          className='product-bg-icons product-bg-icons-2'
        />
        <Icon
          type={IconType.Search}
          color={IconColor.Accent}
          size='28px'
          className='product-bg-icons product-bg-icons-3'
        />
        <Icon
          type={IconType.WebLink}
          color={IconColor.Accent}
          size='28px'
          className='product-bg-icons product-bg-icons-4'
        />
        <Icon
          type={IconType.Plus}
          color={IconColor.Accent}
          size='30px'
          className='product-bg-icons product-bg-icons-5'
        />
        <Icon
          type={IconType.User}
          color={IconColor.Accent}
          size='30px'
          className='product-bg-icons product-bg-icons-6'
				/>
      </div>
    </div>
  );
};

export default Product;
