import React, { useCallback, useMemo, useState } from "react";
import Icon, { IconColor, IconType } from "../../common/Icon";
import Spacer from "../../common/Spacer";

import Text, { TextFont, TextType } from "../../common/Text";

import useLangTranslation, {
  TranslationText,
} from "../../shared/hooks/useLangTranslation";

import "./Pricing.scss";
import { PricingCardPeriodType } from "./types";
import useAdaptiveLayout, {
  AdaptiveLayoutResolution,
} from "../../shared/hooks/useAdaptiveLayout";
import PricingDesktop from "./PricingDesktop";
import { AnimatePresence } from "framer-motion";
import MotionDiv from "../../common/MotionDiv";
import PricingMobile from "./PricingMobile";

const Pricing: React.FC = () => {
  const [t] = useLangTranslation();
  const adaptive = useAdaptiveLayout({
    950: true,
    [AdaptiveLayoutResolution.Desktop]: false,
  });

  const [paymentPeriod, setPaymentPeriod] = useState(PricingCardPeriodType.Yearly);
  const switchPaymentPeriod = useCallback(
    (newPeriod: PricingCardPeriodType) => {
      if (paymentPeriod !== newPeriod) {
        setPaymentPeriod(newPeriod);
      }
    },
    [paymentPeriod]
  );
  const isYearly = useMemo(
    () => paymentPeriod === PricingCardPeriodType.Yearly,
    [paymentPeriod]
	);

  return (
    <div className='pricing-container'>
      <Text
        isCenter
        type={TextType.Large40}
        font={TextFont.Montserrat}
        lineHeight='40px'
        style={{ color: "#2b1c08"}}>
        {t(TranslationText.Section8Title)}
      </Text>

			<Spacer top='30px' />
			
			{/* {tabs.map((tab, i) => <Fragment key={i}>
				<MobileCard {...tab} />
				{i === 0 && <div className='pricing-table-container'>
					<Switch
						value={paymentPeriod}
						onChange={switchPaymentPeriod}
						options={[PricingCardPeriodType.Monthly, PricingCardPeriodType.Yearly]}
						getLabel={(option: string) => option === PricingCardPeriodType.Monthly ?  t(TranslationText.Section8PeriodMonthly) : t(TranslationText.Section8PeriodYearly)}
					/>
					<PricingTable />
				</div>}
			</Fragment>)}*/}

      <AnimatePresence exitBeforeEnter>
        <MotionDiv key={adaptive ? "mobile-pricing" : "desktop-pricing"} style={{width: adaptive ? '100%' : 'auto', zIndex: 100}}>
          {adaptive ? (
            <PricingMobile
              isYearly={isYearly}
              switchPaymentPeriod={switchPaymentPeriod}
              paymentPeriod={paymentPeriod}
            />
          ) : (
            <PricingDesktop
              isYearly={isYearly}
              switchPaymentPeriod={switchPaymentPeriod}
              paymentPeriod={paymentPeriod}
            />
          )}
        </MotionDiv>
      </AnimatePresence>

      <Icon
        type={IconType.Grid}
        color={IconColor.None}
        size='700px'
        className='pricing-bg-grid'
      />

      <Icon
        type={IconType.Search}
        color={IconColor.White}
        size='27px'
        className='pricing-bg-ico pricing-bg-ico-1'
      />
      <Icon
        type={IconType.ArrowRightReverse}
        color={IconColor.White}
        size='29px'
        className='pricing-bg-ico pricing-bg-ico-2'
      />
      <Icon
        type={IconType.Plus}
        color={IconColor.White}
        size='29px'
        className='pricing-bg-ico pricing-bg-ico-3'
      />
      <Icon
        type={IconType.WaveShort}
        color={IconColor.None}
        size='50px'
        className='pricing-bg-ico pricing-bg-ico-4'
      />
      <Icon
        type={IconType.WebLink}
        color={IconColor.White}
        size='28px'
        className='pricing-bg-ico pricing-bg-ico-5'
      />
      <Icon
        type={IconType.WaveMedium}
        color={IconColor.None}
        size='70px'
        className='pricing-bg-ico pricing-bg-ico-6'
      />
    </div>
  );
};

export default Pricing;
